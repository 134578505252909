body {
  background: #e9ecf3 !important;
}

.form-control-feedback {
  position: initial;
}

.ep-error {
  color: #ea4646;
  font-size: 13px;
  padding: 3px 5px;
  display: inline-block;
}

.hl-input-error.hl-input-error.hl-input-error {
  border-color: #b11f24 !important;
}

.hl-form__help {
  color: #b11f24;
  font-size: 13px;
  margin-top: 7px;
  display: inline-block;
  margin-bottom: 10px;
}

.react-codemirror2 span {
  font-family: monospace;
}

.mb-20 {
  margin-bottom: 20px;
}

.success {
  background: green;
  color: #fff;
  padding: 10px;
  display: inline-block;
  width: 100%;
  margin: 10px;
}

.windows-notification-container {
  font-size: 9px;
  width: 45.4375em;
  /* position: absolute; */
  right: 9px;
  bottom: 75px;
  background: rgb(32, 32, 32);
  margin: 0;
  /* margin: 55px 25px 0px 25px; */
  margin-top: 55px;
}

.windows-notification {
  display: flex;
  flex-direction: row;
  position: relative;
}

.windows-notification-left {
  padding: 2.9375em 2em;
}

.windows-notification-right {
  width: 68.36%;
  padding: 2.9375em 0px 2.5em;
}

.windows-notification-icon {
  align-self: center;
  justify-self: center;
  padding: 26.3% 7.73% 26.3% 17.02%;
}

.windows-notification-right-title {
  color: rgb(255, 255, 255);
  font-size: 190%;
  letter-spacing: 0.1px;
  max-height: 40px;
  margin: 0px;
  overflow: hidden;
}

.windows-notification-right-desc {
  letter-spacing: 0px;
  font-size: 190%;
  font-weight: 400;
  max-height: 80px;
  white-space: pre-wrap;
  margin: 0px;
  overflow: hidden;
  color: rgb(167, 167, 167);
  font-family: "Segoe UI", Roboto, Proxima-Nova, "Proxima Nova", "Open Sans",
    Arial, Helvetica, sans-serif;
}

.windows-notification-right-brand {
  font-size: 150%;
  margin-top: 10px;
  color: rgb(167, 167, 167);
  font-family: "Segoe UI", Roboto, Proxima-Nova, "Proxima Nova", "Open Sans",
    Arial, Helvetica, sans-serif;
}

.notification-preview {
  position: relative;
  width: 100%;
  height: 300px;
}

.windows-notification-icon svg {
  width: 15px;
}

.android-notification-container {
  /* position: relative; */
  top: 280px;
  width: 90%;
  cursor: auto;
  margin: 0;
}

.android-notification {
  height: 57px;
  font-family: Roboto, Proxima-Nova, "Proxima Nova", "Open Sans", Arial,
    Helvetica, sans-serif;
  background-color: rgb(254, 254, 254);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  max-width: 500px;
  overflow-y: hidden;
  box-sizing: content-box;
  padding: 16px;
  border-radius: 2px;
  transition: height 0.3s ease-out 0s;
}

.android-notification.with-image {
  height: 270px;
}

.android-notification-top {
  display: flex;
  font-size: 12px;
  line-height: 15px;
}

.android-notification-top svg {
  width: 13.12px;
  height: 13.12px;
  fill: rgb(117, 117, 117);
  margin-right: 5.9px;
}

.android-notification-top span {
  color: rgb(99, 99, 99);
  margin-right: 4px;
}

.android-notification-content {
  margin-top: 8px;
}

.android-notification-content-container {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  line-height: 1px;
  text-align: left;
}

.android-notification-content-title {
  color: rgb(83, 83, 83);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
  display: inline-block;
  width: 315px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.android-notification-content-desc {
  font-size: 14px;
  line-height: 20px;
  color: rgb(117, 117, 117);
  font-weight: 400;
  letter-spacing: 0.1px;
  display: inline-block;
  width: 315px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.android-notification-container {
  width: 450px;
  /* height: 660px; */
  background-image: "";
  line-height: 1px;
  margin: 0px;
  transition: height 0.3s ease-out 0s;
  /* margin: 55px 25px 0px 25px; */
  margin-top: 55px;
}

.android-notification-image {
  display: flex;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  background-color: rgb(254, 254, 254);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.macos-notification-wrapper {
  /* width: 532px; */
  width: 478px;
  /* height: 605px; */
  background-image: "";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  margin: 0px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(228, 228, 228);
  border-image: initial;
  border-radius: 7px;
  border: 0;
  margin-top: 55px;
}

.macos-notification-container {
  display: inline-grid;
  /* grid-template-columns: 13.89fr 86.11fr; */
  grid-template-columns: 13.89fr 66.73fr 19.38fr;
  font-family: Roboto, Proxima-Nova, "Proxima Nova", "Open Sans", Arial,
    Helvetica, sans-serif;
  max-width: 100%;
  max-height: 106px;
  background-color: rgb(240, 240, 240);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 13px 1px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  user-select: none;
  /* position: relative; */
  top: 55px;
  left: 0px;
  border-radius: 8px;
  /* margin: auto 25px; */
  margin: auto;
  line-height: 1.42857;
}

.macos-notification-icon {
  align-self: center;
  justify-self: center;
  padding: 26.3% 7.73% 26.3% 17.02%;
}

.macos-notification-icon svg {
  width: 100%;
}

.macos-notification-content {
  align-self: center;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 13px;
  padding: 0px 2.44%;
}

.macos-notification-content-title {
  width: 100%;
  font-weight: 500;
  color: rgb(77, 77, 77);
  font-size: 1.5em;
  letter-spacing: 0.5px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.macos-notification-content-site {
  width: 100%;
  font-weight: 500;
  color: rgb(77, 77, 77);
  font-size: 18.72px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.macos-notification-content-desc {
  width: 100%;
  font-weight: 400;
  color: rgb(77, 77, 77);
  font-size: 1.4em;
  letter-spacing: 0.5px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.macos-notification-image {
  align-self: center;
  justify-self: center;
  height: 100%;
  padding: 12.44%;
}

.macos-notification-image img {
  max-width: 100%;
  max-height: 100%;
}

.w-100 {
  width: 100%;
}

.nav-tabs>li>a {
  margin-right: 0;
}

.nopadding {
  padding: 0 !important;
}

.nopadding-r {
  padding-right: 0 !important;
}

.d-form .form-group {
  margin: 0;
}

.btn i {
  margin-right: 10px;
}

.btn--merged {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  left: -2px;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

textarea.form-control {
  height: 75px !important;
}

.react-datepicker {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.2);
}

.react-datepicker__header {
  background-color: #447efe !important;
  border-bottom: 1px solid #306ef3 !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: #fff !important;
}

.react-datepicker-time__header {
  text-align: left;
  padding-left: 10px;
}

.react-datepicker__time-container {
  border: 0 !important;
}

.react-datepicker__header--time {
  padding-bottom: 28px !important;
  border-left: solid 1px #588bfb !important;
}

.react-datepicker__time-container .react-datepicker__time {
  border-left: solid 1px #eee !important;
}

.react-datepicker__navigation--next {
  border-left-color: #fff !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #fff !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 93px !important;
}

.react-datepicker__navigation {
  border-width: 0.4rem !important;
  opacity: 0.5 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #306ef3 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  padding: 8px 10px !important;
}

input:disabled {
  background: #eee !important;
}

.posrel {
  position: relative;
}

.preview-image {
  position: absolute;
  top: 29px;
  left: 10px;
  border: solid 1px #bbb;
}

.rdt_TableHeadRow .rdt_TableCol {
  vertical-align: bottom !important;
  border-bottom: 1px solid rgba(233, 236, 243, 0.88) !important;
  border-top: 1px solid rgba(233, 236, 243, 0.88) !important;
  background: rgba(233, 236, 243, 0.3) !important;
  color: #727c8c !important;
  font-weight: 500 !important;
  border-right: 1px solid rgba(233, 236, 243, 0.88) !important;
}

/* .rdt_TableHeader {
  display: none!important;
} */

.btn-icon {
  border: 1px solid #eee !important;
  background: #eee;
  color: #4680ff;
  padding: 5px 10px;
}

.rdt_TableCol_Sortable span {
  font-size: 11px !important;
  color: #316ff4 !important;
  margin-left: 10px !important;
  display: inline-block !important;
  padding: 0 !important;
}

.d-header__left .ui.dropdown>.dropdown.icon:before {
  color: #316ff4;
}

.btn-gray {
  background: #eee !important;
}

.d-header__left .ui.dropdown>.text {
  line-height: 26px;
  font-size: 20px;
}

.d-card__body .chart-header__action-box {
  outline: 0;
}

.d-header__right-icon {
  outline: 0;
}

.alert {
  background: #ffffff;
  color: #694a4a;
  position: fixed;
  top: 10px;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px #da7878;
  max-width: 300px;
}

.ui-blocker {
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 106%;
  background: #ffffff9e;
  z-index: 1000;
}

.ui-blocker.loader {
  background-image: url("../images/loader.svg");
  background-repeat: no-repeat;
  background-position: center 50%;
  position: fixed;
}