	/*
  	Flaticon icon font: Flaticon
  	Creation date: 08/12/2017 11:44
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-envelope:before { content: "\f100"; }
.flaticon-letter:before { content: "\f101"; }
.flaticon-mail-1:before { content: "\f102"; }
.flaticon-mail:before { content: "\f103"; }
.flaticon-technology-1:before { content: "\f104"; }
.flaticon-technology:before { content: "\f105"; }
.flaticon-note:before { content: "\f106"; }
.flaticon-circle:before { content: "\f107"; }
.flaticon-food:before { content: "\f108"; }
.flaticon-bell:before { content: "\f109"; }
.flaticon-cocktail:before { content: "\f10a"; }
.flaticon-bath:before { content: "\f10b"; }
.flaticon-dumbbell:before { content: "\f10c"; }
.flaticon-swimming-pool:before { content: "\f10d"; }
.flaticon-customer-service:before { content: "\f10e"; }
.flaticon-room-service:before { content: "\f10f"; }
.flaticon-reception:before { content: "\f110"; }