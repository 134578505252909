.hl-btn {
  padding: 12px 55px;
  border-radius: 0;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  background: #ad9782;
  border-color: #ad9782;
  font-size: 13px;
}

.hl-btn:hover {
  color: #fff;
  background: #a38a73;
}

.hl-btn.hl-btn--type-2 {
  background: transparent;
  border-color: #ad9782;
  color: #ad9782;
}

.hl.banner-1 {
  background-size: cover;
  height: 100%;
  position: relative;
}

.hl.banner-1 .container {
  height: 100%;
}

.hl.banner-1 .feature-text {
  font-family: "Libre Baskerville", "Roboto", sans-serif;
}

.hl.banner-1:before {
  content: "";
  background: #000;
  opacity: .5;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hl.banner-1 .sponsor-list {
  margin-top: 60px;
}

.hl .banner-1__hero-container {
  position: absolute;
  z-index: 11;
  padding: 20px 0px;
  color: #fff;
  bottom: 15%;
  left: 0;
  width: 100%;
}

.hl .hero-text {
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 20px;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: visibility .5s ease, opacity .5s ease, transform .5s ease;
  -moz-transition: visibility .5s ease, opacity .5s ease, transform .5s ease;
  -ms-transition: visibility .5s ease, opacity .5s ease, transform .5s ease;
  -o-transition: visibility .5s ease, opacity .5s ease, transform .5s ease;
  transition: visibility .5s ease, opacity .5s ease, transform .5s ease;
}

.hl .hero-text.slide-up {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -ms-transform: translateY(100px);
  -o-transform: translateY(100px);
  transform: translateY(100px);
}

.hl .sponsor-list p {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}

.hl .sponsor-list li {
  display: inline-block;
  margin-right: 30px;
}

/* home-page-3 */
.home-page-3 .hl.banner-1 {
  /* background: url(../images/banner/banner3.jpg) no-repeat; */
  background-size: cover;
}

.home-page-3 .enquiry-form {
  margin-left: 50px;
  margin-top: 16%;
}

.banner-1__inner {
  position: relative;
  z-index: 11;
  overflow: hidden;
  padding-top: 125px;
  height: 100%;
}

.banner-1__left-content {
  color: #fff;
  width: 450px;
  float: right;
  margin-top: 20%;
}

.banner-1__left-content h1 {
  font-size: 48px;
}

.banner-1__left-content p {
  line-height: 31px;
  margin-top: 20px;
  font-size: 17px;
}

@media (max-width: 768px) {
  .hl .banner-1__hero-container {
    position: relative;
    padding-top: 50px;
    bottom: auto;
  }

  .hl .hero-text {
    font-size: 37px;
    margin-bottom: 0;
    line-height: 53px;
  }

  .hl .sponsor-list li {
    margin-right: 15px;
  }
}

body,
html {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  line-height: inherit;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

* {
  box-sizing: border-box;
}

a,
span {
  color: inherit;
  text-decoration: none;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

p,
span,
a,
div,
li {
  font-weight: 400;
}

a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin-top: 8px;
  margin-bottom: 5px;
  font-family: "Libre Baskerville", "Roboto", sans-serif;
}

.t1,
.t2,
.t3,
.s1,
.s2 {
  margin-top: 8px;
  margin-bottom: 5px;
}

.t1 {
  font-size: 36px;
}

.t2 {
  font-size: 30px;
}

.t3 {
  font-size: 24px;
}

.s1 {
  font-size: 13px;
}

.s2 {
  font-size: 11px;
}

.extra-bold {
  font-weight: 900;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.thin {
  font-weight: 300;
}

.very-thin {
  font-weight: 100;
}

.clearfix {
  clear: both;
}

.container {
  position: relative;
}

.hl-link {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: .5px;
  color: #000000;
  text-transform: uppercase;
  border-bottom: 2px solid #000000;
  padding-bottom: 2px;
}

.hl-link:hover {
  color: #ad9782;
  border-color: #ad9782;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* section */
.section-2 {
  height: 100%;
  padding-top: 125px;
}

.page-item.disabled .page-link {
  color: #636c72;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
  opacity: 0.7;
}

.page-item.active .page-link {
  z-index: 2;
  color: #fff;
  background-color: #427dfd;
  border-color: #0275d8;
}

.page-link {
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: none;
  border-radius: 24px;
  margin: 0 6px;
}

.page-item:last-child .page-link {
  border-radius: 50px;
  width: auto;
}

.page-item:first-child .page-link {
  margin-left: 0;
  width: auto;
  border-radius: 50px;
}

.small-block-1 {
  background: #000;
  height: 45px;
  color: #d4d0cd;
  font-size: 14px;
}

.small-block-1 .icon-text-link {
  margin-right: 25px;
}

.icon-text-link {
  display: inline-block;
}

.icon-text-link i {
  margin-right: 5px;
}

.l-small-block-1 {
  padding: 13px 50px;
}

.top-header-dropdown {
  display: inline-block;
  width: 60px;
}

.top-header-dropdown .ui.dropdown {
  background: transparent;
  width: 100%;
  min-width: 100%;
  display: inline-block;
  padding: 0;
  border: none;
  min-height: auto;
  color: #d4d0cd;
}

.top-header-dropdown .ui.dropdown .menu {
  top: 30px;
  color: #d4d0cd;
  background: #000;
}

.top-header-dropdown .ui.selection.active.dropdown .menu {
  border: none;
}

.top-header-dropdown .ui.dropdown .menu>.item {
  color: #d4d0cd;
}

.top-header-dropdown .ui.selection.dropdown .menu>.item {
  border-color: rgba(212, 208, 205, 0.4);
}

.top-header-dropdown .ui.selection.visible.dropdown>.text:not(.default) {
  color: #d4d0cd;
}

@media (max-width: 768px) {
  .l-small-block-1 {
    padding: 16px 0px;
  }

  .small-block-1 .icon-text-link {
    margin-right: 8px;
  }

  .top-header-dropdown {
    width: 40px;
  }

  .top-header-dropdown .ui.selection.dropdown>.search.icon,
  .top-header-dropdown .ui.selection.dropdown>.delete.icon,
  .top-header-dropdown .ui.selection.dropdown>.dropdown.icon {
    top: 1px;
  }

  .top-header-dropdown .ui.selection.dropdown .menu>.item {
    padding: 10px !important;
  }

  .top-header-dropdown .ui.dropdown .menu {
    top: 28px;
  }

  .small-block-1 {
    font-size: 11px;
  }
}

.header {
  position: fixed;
  width: 100%;
  z-index: 1111;
  top: 0;
  left: 0;
  -webkit-transition: top .3s ease;
  -moz-transition: top .3s ease;
  -ms-transition: top .3s ease;
  -o-transition: top .3s ease;
  transition: top .3s ease;
}

.header .hl-btn {
  padding: 12px 30px;
}

.header.is-header__slide-top {
  top: -45px;
}

.section-new {
  margin-top: 125px;
}

@media (min-width: 768px) {
  .header {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.14);
  }

  .mobile-barmenu {
    display: none;
  }

  .nav-block-1 {
    background: #232323;
  }

  .nav-block-1 .nav-block-1__menu-list>li {
    display: inline-block;
    position: relative;
  }

  .nav-block-1 .nav-block-1__menu-list>li a {
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
  }

  .nav-block-1 .nav-block-1__menu-list>li a:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: #ad9782;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    transform-origin: 0 0;
    -webkit-transition: transform .3s ease;
    -moz-transition: transform .3s ease;
    -ms-transition: transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    z-index: 1;
  }

  .nav-block-1 .nav-block-1__menu-list>li a:hover:before {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }

  .nav-block-1 .nav-block-1__menu-list>li a:hover {
    color: #ad9782;
  }

  .l-nav-block-1 {
    padding: 15px 50px;
    height: 80px;
  }

  .l-nav-block-1 .nav-block-1__menu-list {
    padding-top: 15px;
    color: #fff;
  }

  .l-nav-block-1 .nav-block-1__menu-list>li {
    margin-right: 35px;
    padding: 0px 0 28px 0;
  }

  .menu-dropdown-icon {
    opacity: .7;
    margin: 0 0 0 5px;
    position: relative;
    top: 0px;
  }

  .l-action-btn {
    margin-top: 4px;
  }

  .nav-block-1__sub-menu-list {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 111;
    background: #fff;
    padding: 25px;
    width: 500px;
    box-shadow: 3px 7px 16px 0px rgba(0, 0, 0, 0.14);
    text-align: left;
    margin-top: 36px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -moz-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -ms-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -o-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    transition: opacity .3s ease, visibility .3s ease, top .3s ease;
  }

  .nav-block-1__sub-menu-list>li {
    margin-bottom: 12px;
  }

  .nav-block-1__sub-menu-list a {
    margin-bottom: 15px !important;
    color: #000000;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
  }

  .nav-block-1__sub-menu-list ul {
    margin-top: 15px;
  }

  .nav-block-1__menu-list>li:hover .nav-block-1__sub-menu-list {
    opacity: 1;
    visibility: visible;
    top: 14px;
  }

  .sub-list__heading {
    color: #000;
    font-weight: 700;
    letter-spacing: .3px;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .multiple-column .sub-list {
    display: inline-block;
    vertical-align: top;
  }

  .multiple-column.three-column .sub-list {
    width: 32%;
  }

  .multiple-column.two-column .sub-list {
    width: 49%;
  }

  .multiple-column.four-column .sub-list {
    width: 24%;
  }

  .multiple-column.five-column .sub-list {
    width: 19%;
  }
}

@media (max-width: 768px) {
  .section-new {
    margin-top: 103px;
  }

  .menu-dropdown-icon {
    position: absolute;
    right: 14px;
    top: 18px;
    transform: rotate(-90deg);
  }

  .nav-block-1__menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;
    background: #fff;
    width: 300px;
    height: 100%;
    color: #000;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    overflow: hidden;
  }

  .nav-block-1__sub-menu-list,
  .nav-block-1__menu-wrapper {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: transform .3s ease;
    -moz-transition: transform .3s ease;
    -ms-transition: transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    left: -300px;
  }

  .nav-block-1__menu {
    position: relative;
    left: 0;
    -webkit-transition: left .3s ease;
    -moz-transition: left .3s ease;
    -ms-transition: left .3s ease;
    -o-transition: left .3s ease;
    transition: left .3s ease;
  }

  .nav-block-1__menu.is-fade {
    left: 20px;
  }

  .nav-block-1__sub-menu-list.is-open,
  .nav-block-1__menu-wrapper.is-open {
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    -o-transform: translateX(300px);
    transform: translateX(300px);
  }

  .nav-block-1__menu-list>li a {
    font-size: 14px;
    padding: 15px 40px;
    display: inline-block;
    width: 100%;
    position: relative;
  }

  .nav-block-1__menu-list>li a:active {
    background: #efefef;
  }

  .nav-block-1__menu-list .nav-block-1__sub-menu-list {
    position: fixed;
    top: 0;
    left: -100%;
    background: #fff;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding-top: 30px;
  }

  .nav-block-1__menu-list .back-menu-btn {
    position: absolute;
    top: 2px;
    left: 30px;
    padding: 10px;
    color: #999;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .hl-btn {
    padding: 10px 31px;
    font-size: 11px;
  }

  .nav-block-1 {
    background: #000;
    padding: 8px 0;
  }

  .mobile-barmenu {
    display: inline-block;
  }

  .mobile-barmenu img {
    width: 21px;
    margin-right: 15px;
    cursor: pointer;
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 111;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -moz-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -ms-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    -o-transition: opacity .3s ease, visibility .3s ease, top .3s ease;
    transition: opacity .3s ease, visibility .3s ease, top .3s ease;
  }

  .sidebar-overlay:hover {
    cursor: url(../images/crosswhite.png), auto;
  }

  .sidebar-overlay.is-open {
    visibility: visible;
    opacity: 1;
  }
}

.hl-card {
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.04);
}

.hl-card__image-box img {
  width: 100%;
}

.hl-card__content-box {
  padding: 40px;
  position: relative;
}

.hl-card__content-box .top-hint {
  font-size: 12px;
  letter-spacing: .3px;
  color: #a4a5a6;
}

.hl-card__content-box h3 {
  font-size: 26px;
  line-height: 36px;
}

.hl-card__content-box p {
  font-size: 17px;
  line-height: 27px;
  letter-spacing: .1px;
  color: #565656;
  font-weight: 300;
  margin: 15px 0 30px 0;
}

.price-tag {
  font-size: 24px;
  color: #ad9782;
  font-family: "Libre Baskerville", "Roboto", sans-serif;
  margin-right: 5px;
}

.price-tag-cross {
  color: #b5b4b4;
  text-decoration: line-through;
}

.icon-text-link--card-link span,
.icon-text-link--card-link a {
  color: #656666;
  text-transform: uppercase;
  letter-spacing: .25px;
  font-size: 11px;
}

/*card type 2 */
.hl-card--type-2 {
  min-height: 400px;
  padding: 30px 10px 65px 10px;
  position: relative;
}

.hl-card--type-2.hl-card {
  margin: 0 15px;
  box-shadow: 5px 6px 32px 6px rgba(0, 0, 0, 0.06);
}

.hl-card--type-2 .hl-card__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 25px;
  z-index: 1;
  width: 100%;
}

.hl-card--type-2 .hl-card__footer-right {
  float: right;
}

.hl-card--type-2 .hl-card__footer-left {
  display: inline-block;
}

.hl-card--type-2 h3 {
  margin-bottom: 15px;
}

.hl-card__profile-detail {
  display: inline-block;
}

.hl-card__profile-detail .profile-detail__image {
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.hl-card__profile-detail .profile-detail__image img {
  width: 40px;
  height: 40px;
}

.hl-card__profile-detail .profile-detail__content {
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px;
}

.hl-card__profile-detail .profile-detail__content h4 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 1px;
}

/*section 8*/
.slider-block-4 .hl-card .simple-text-block-1 h3 {
  font-size: 24px;
  line-height: 40px;
}

.slider-block-4 .hl-card .simple-text-block-1.simple-text-block-1--style-2 {
  margin-bottom: 20px;
}

.slider-block-4 .hl-card .simple-text-block-1.simple-text-block-1--style-2 p {
  font-size: 15px;
  margin-bottom: 18px;
  margin-top: 10px;
}

.hl-card.horizontal-card .hl-card__image-box {
  height: auto;
}

@media (max-width: 768px) {
  .l-cards-block-1 {
    margin-bottom: 20px;
  }

  .hl-card--type-2.hl-card {
    margin: 0;
  }
}

.block-1--style {
  background: #232323;
  padding: 100px 0;
  color: #fff;
}

.text-block1__heading {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}

.text-block1__content {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #c1c1c1;
}

.element1 {
  margin-bottom: 40px;
  position: relative;
}

.element1__img {
  position: absolute;
  left: 0;
  top: 0;
}

.element1__img img {
  width: 45px;
}

.text-block1 {
  padding-left: 65px;
  padding-right: 20px;
}

.l-block1 .hl-btn {
  margin-top: 30px;
}

/* block-2 */
.block-2 {
  background: url("../images/img1.png") no-repeat;
  background-size: cover;
  position: relative;
}

.block-2.dark .simple-text-block-1 p {
  color: #fff;
}

.l-block-2 {
  padding: 100px 0;
}

.simple-text-block-1 {
  width: 390px;
  display: inline-block;
  padding: 0 10px;
  position: relative;
  z-index: 11;
}

.simple-text-block-1.dark h3 {
  color: #fff;
}

.simple-text-block-1 span {
  font-size: 14px;
  letter-spacing: 1.3px;
  line-height: 48px;
  color: #ad9782;
  text-transform: uppercase;
}

.simple-text-block-1 h3 {
  font-size: 38px;
  line-height: 48px;
  letter-spacing: -0.1px;
}

.simple-text-block-1 p {
  font-size: 17px;
  line-height: 25px;
  letter-spacing: normal;
  margin: 45px 0;
  font-weight: 300;
}

/* block5 */
.slider-block {
  padding: 50px 0;
}

.simple-text-block-1.simple-text-block-1--style-2 {
  width: 100%;
}

.simple-text-block-1.simple-text-block-1--style-2 p {
  margin: 18px 0px 10px;
  font-weight: 300;
}

.simple-text-block-1--style-2 {
  margin-bottom: 50px;
}

/* slider-block-2 */
section .row {
  margin: 0;
}

.slider-block-2 img {
  width: 100%;
}

.slider-block-2 .gradient-overlay {
  background: rgba(0, 0, 0, 0.5);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.57) 41%, rgba(0, 0, 0, 0.22) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.57) 41%, rgba(0, 0, 0, 0.22) 100%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.57) 41%, rgba(0, 0, 0, 0.22) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6ffffff', GradientType=1);
}

.slider-block-2__box {
  position: relative;
  height: 600px;
  width: 100%;
}

.slider-block-2__box-content {
  position: absolute;
  bottom: 0;
  z-index: 11;
  color: #fff;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
}

.slider-block-2__box-content-left {
  width: 70%;
  display: inline-block;
  vertical-align: middle;
}

.slider-block-2__box-content-left p {
  font-size: 14px;
  color: #b2b2b2;
}

.slider-block-2__box-content-right {
  width: 28%;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
}

.slider-block-2__box-content-right .hl-btn {
  padding: 10px 25px;
}

.l-slider-block-3 {
  margin-bottom: 100px;
}

/* form-block-1 */
.form-block-1 {
  /* background: url("../images/sliderimages/sliderimage1.jpg") no-repeat; */
  background-size: cover;
  position: relative;
  min-height: 400px;
  padding: 50px 0;
}

.form-block-1 .container {
  position: relative;
  z-index: 11;
}

.form-block-1 .hl-dropdown {
  top: 18px;
  right: 20px;
}

.form-block-1 .ui.selection.dropdown {
  min-width: 100%;
}

.form-block-1 .simple-text-block-1 {
  color: #fff;
  padding-top: 40px;
}

.form-block-1 .simple-text-block-1 h3 {
  font-size: 36px;
  line-height: 41px;
  letter-spacing: -0.1px;
}

.form-block-1 .simple-text-block-1 p {
  margin: 30px 0 30px 0;
}

.form-block-1 .ui.selection.active.dropdown,
.form-block-1 .ui.selection.active.dropdown .menu {
  border: none;
}

.form-block-1 .ui.dropdown .menu {
  top: 0;
}

.form-block-1 .ui.selection.dropdown>.search.icon,
.form-block-1 .ui.selection.dropdown>.delete.icon,
.form-block-1 .ui.selection.dropdown>.dropdown.icon {
  top: 11px;
}

.enquiry-form {
  max-width: 500px;
  background: #fff;
  padding: 30px 20px;
  box-shadow: 6px 6px 19px 0 rgba(2, 2, 2, 0.29);
}

.enquiry-form .hl-btn {
  width: 100%;
}

.enquiry-form textarea {
  height: 100px;
}

.enquiry-form .ui.dropdown {
  width: 100%;
}

.section-8 {
  padding: 50px 0;
}

/* feature-block-2 */
.feature-block-2 .simple-text-block-1 {
  width: 100%;
}

.feature-block-2 .slider-block-2__box {
  height: 450px;
  margin-bottom: 30px;
}

.feature-block-2 .slider-block-2__box img {
  height: 100%;
}

.feature-block-2 .slider-block-2__box .pop-up-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 190px;
  height: 45px;
  z-index: 11;
  display: none;
}

.feature-block-2 .slider-block-2__box .pop-up-info .hl-btn {
  border-color: #fff;
  color: #fff;
  font-weight: 300;
  font-size: 16px;
}

.feature-block-2 .slider-block-2__box .gradient-overlay {
  background: transparent;
  -webkit-transition: box-shadow .3s ease;
  -moz-transition: box-shadow .3s ease;
  -ms-transition: box-shadow .3s ease;
  -o-transition: box-shadow .3s ease;
  transition: box-shadow .3s ease;
  box-shadow: inset 0px -161px 120px -49px rgba(0, 0, 0, 0.37);
}

.feature-block-2 .slider-block-2__box:hover .gradient-overlay {
  box-shadow: inset 0px -397px 228px -49px rgba(0, 0, 0, 0.37);
}

.feature-block-2 .slider-block-2__box:hover .pop-up-info {
  display: block;
}

.hl.enquiry-section {
  background: #fff;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.04);
  max-width: 1170px;
  margin: auto;
  top: -37px;
  position: relative;
  z-index: 111;
  height: 70px;
}

.hl .enquiry-section__enquiry-list {
  margin: 0;
  height: 100%;
  content: "";
  display: table;
  width: 100%;
}

.hl .enquiry-list__item {
  float: left;
  border-right: 1px solid #f3f3f3;
  width: 16.6666666%;
  height: 100%;
  vertical-align: middle;
  position: relative;
}

.hl .enquiry-list__item label {
  margin-bottom: 1px;
}

.hl .enquiry-list__item:last-child {
  border: none;
}

.hl .enquiry-list__item .ui.dropdown {
  position: absolute;
  left: 0;
  top: 0;
  border-color: transparent;
  padding: 34px 38px 21px 24px;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  min-width: 100%;
  min-height: 33px;
}

.hl .enquiry-list__item .ui.selection.active.dropdown:hover {
  border-color: transparent;
  box-shadow: none;
}

.hl .enquiry-list__item .ui.selection.dropdown .menu>.item {
  padding-left: 24px !important;
}

.hl .enquiry-list__item .ui.selection.active.dropdown .menu {
  border: none;
  border-radius: 0;
}

.hl .enquiry-list__item a {
  padding: 26px 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  letter-spacing: 2px;
}

.hl .enquiry-list__item .enquiry-list__item-data {
  padding: 15px 0 0 25px;
}

.hl .enquiry-list__item label {
  display: block;
  font-weight: bold;
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
}

.hl .enquiry-list__item__input {
  border: none;
  background: transparent;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 35px 0px 19px 25px;
  z-index: 1;
  width: 100%;
}

.hl .enquiry-list__item__input:hover,
.hl .enquiry-list__item__input:focus {
  border: none;
  outline: 0;
}

/*section-11*/
.section-11 {
  padding: 50px 0;
}

.hl.heading-2 {
  font-size: 24px;
}

.hl.card-block-2 {
  margin-bottom: 30px;
  max-height: 282px;
  overflow: hidden;
}

.hl.card-block-2 .starrr .fa {
  color: #ffbe00;
}

.hl.card-block-2 .feature-list .feature-list__item {
  display: inline-block;
  width: 49%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: .5px;
  padding: 7px;
  padding-left: 44px;
  background-size: 33px !important;
}

.hl.card-block-2,
.hl.card-block-3 {
  box-shadow: 3px 5px 27px 0 rgba(0, 0, 0, 0.07);
}

.hl.card-block-2:after,
.hl.card-block-3:after {
  content: "";
  display: table;
  clear: both;
}

.hl .card-block-2__img-box {
  width: 311px;
  float: left;
}

.hl .card-block-2__img-box img {
  width: 100%;
}

.hl .card-block-2__content-box {
  width: calc(100% - 311px);
  float: left;
  padding: 20px;
}

.hl .card-block-2__content-box .card-block-2__item {
  display: inline-block;
  width: 49%;
  margin-bottom: 15px;
  font-size: 16px;
  letter-spacing: .5px;
  color: #4c4b4b;
}

.hl .price-box {
  margin-top: 25px;
  margin-bottom: 15px;
}

.hl .price-tag {
  font-size: 36px;
  color: #000;
}

.hl .price-tag-cross {
  font-size: 20px;
}

.hl .card-block-2__footer-left p {
  font-size: 14px;
  color: #878585;
  letter-spacing: .25px;
  margin-bottom: 6px;
}

.hl .card-block-2__footer-left li {
  display: inline-block;
  margin-right: 20px;
  font-size: 16px;
}

.hl .card-block-2__footer-left span {
  margin-left: 10px;
}

.section-12 {
  padding: 50px 0;
}

.hl.card-block-3 {
  min-height: 365px;
  max-height: 365px;
  overflow: hidden;
}

.hl .card-block-3__img-box {
  float: left;
  width: 60%;
}

.hl .card-block-3__img-box img {
  width: 100%;
}

.hl .card-block-3__content-box {
  float: right;
  width: 40%;
  padding: 50px 50px 20px;
}

.hl .card-block-3__content-box p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
}

.hl .card-block-3__content-box .card-link {
  font-size: 16px;
}

.hl .card-block-3__content-box .hl-btn {
  padding: 12px 38px;
}

.hl.card-block-3.card-block-3--reverse .card-block-3__img-box {
  float: right;
}

.hl.card-block-3.card-block-3--reverse .card-block-3__content-box {
  float: left;
}

/*Room page*/
.para-2 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .25px;
  color: #999999;
  font-weight: 300;
}

.para-2.l-para-2 {
  margin: 20px 0 20px;
}

.room-detail-section {
  margin-top: 125px;
}

.datepicker-card .select-date-rooms {
  display: inline-block;
  vertical-align: top;
}

.datepicker-card .check-room-date {
  width: 130px;
  padding: 8px;
}

.datepicker-card .check-in-date:before {
  background: #000;
  top: 30px;
}

.availability-btn {
  background: #000;
  padding: 20px 45px;
  border: none;
  cursor: pointer;
}

.availability-btn:focus {
  outline: 0;
}

.availability-btn_span1,
.availability-btn_span2 {
  letter-spacing: .5px;
  font-weight: 100;
  text-transform: uppercase;
  color: #feffff;
  display: block;
}

.availability-btn_span1 {
  font-size: 24px;
}

.availability-btn_span2 {
  font-size: 12px;
}

.room-page .datepicker-card {
  margin-bottom: 50px;
}

.room-page .slider-block {
  padding-top: 0;
}

.room-page .review-block-1 {
  margin-bottom: 50px;
}

.room-page .hl .feature-list__item {
  text-align: left !important;
}

.room-page .page-block {
  padding-top: 125px;
}

.dropdown-menu {
  z-index: 1111;
}

.page-block-1 {
  height: 100%;
}

.page-block-1 .img-slider-4 {
  height: 100%;
}

.page-block-1 .page-block-1__left {
  width: 40%;
  position: fixed;
  left: 0;
  top: 125px;
  z-index: 11;
  height: calc(100% - 125px);
}

.page-block-1 .page-block-1__left img {
  width: 100%;
  height: 100%;
}

.page-block-1 .page-block-1__right {
  width: calc(100% - 40%);
  margin-left: 40%;
  padding: 45px;
}

.hl {
  /*block-6*/
}

.hl.block-5 .block-5__detail-text {
  font-size: 14px;
  letter-spacing: 1px;
  color: #666;
}

.hl.block-6 .hl.heading-2 {
  font-size: 30px;
}

.hl.block-6 .para-2 {
  font-size: 14px;
}

.hl .feature-list.l-feature-list {
  margin-top: 30px;
}

.hl .feature-list .feature-list__item {
  display: inline-block;
  width: 32%;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .5px;
  padding: 9px;
  padding-left: 50px;
}

.hl .feature-list .feature-list__item-1 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-2 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-3 {
  background: url(../images/icons/customer-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-4 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-5 {
  background: url(../images/icons/bath.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-6 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-7 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

.hl .feature-list .feature-list__item-8 {
  background: url(../images/icons/room-service.svg) no-repeat;
  background-size: 37px;
}

/*review-block*/
.review-block-1 .hl.heading-2 {
  font-size: 30px;
}

.review-block-1 .para-2 {
  font-size: 14px;
}

.review-block-1 .card-6 {
  margin-top: 30px;
}

.rating-text {
  display: inline-block;
  font-size: 16px;
  color: #ffffff;
  background: #ad9782;
  padding: 2px 8px;
  margin-left: 6px;
}

.write-review-block form {
  margin-top: 20px;
}

.write-review-block textarea {
  width: 100%;
  height: 200px;
  padding: 26px;
  margin-bottom: 15px;
  font-size: 16px;
  color: #424242;
  font-weight: 100;
  border-color: #e6e6e6;
}

.write-review-block textarea:focus {
  outline: 0;
}

.comment-box {
  background: #f5f5f5;
  padding: 10px 20px;
  margin-top: 18px;
  margin-left: 120px;
}

.comment-box .hl.card-6 {
  margin-top: 0;
}

.comment-box .hl .card-6__content-box {
  padding-left: 76px;
}

.comment-box .hl .card-6__img-box {
  width: 60px;
  height: 60px;
}

.comment-box .hl .card-6__img-box img {
  width: 60px;
  height: 60px;
}

.comment-box .hl .card-para.card-para {
  font-size: 14px;
  margin-top: 1px;
  line-height: 22px;
}

.comment-box .hl .card-user-name {
  font-size: 16px !important;
}

.comment-box .hl.l-card-block-6 {
  padding-bottom: 0 !important;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.write-comment-box {
  position: relative;
  margin-left: 120px;
  margin-top: 20px;
}

.write-comment-box .card-6__img-box img {
  width: 50px !important;
  height: 50px !important;
}

.write-comment-box .card-6__img-box {
  width: 50px !important;
  height: 50px !important;
  left: 4px !important;
  top: 3px !important;
}

.comment-textarea textarea {
  height: 56px;
  padding-left: 70px !important;
  border-radius: 100px;
  font-size: 16px;
}

.review-footer>a {
  margin-right: 25px;
  font-size: 13px;
}

.review-footer i {
  margin-left: 5px;
}

.review-footer .no-margin {
  margin: 0;
}

.review-footer .share-btn {
  padding: 0;
  width: 105px;
  padding-top: 0px;
  vertical-align: top;
}

.review-footer .share-btn .share-btn__text {
  font-size: 14px;
  letter-spacing: 0;
  text-transform: capitalize;
}

.review-footer .share-btn .share-btn__icon span {
  margin: 0 1px;
}

.hl.card-6 {
  position: relative;
}

.hl .card-6__img-box {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
}

.hl .card-6__img-box img {
  width: 100px;
  height: 100px;
}

.hl .card-6__content-box {
  padding-left: 120px;
  padding-top: 10px;
}

.hl .card-6__content-box .card-user-name {
  font-size: 18px;
  letter-spacing: .1px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 6px;
  display: inline-block;
  font-family: "libre baskerville";
}

.hl .card-6__content-box .card-6__content-box-inner {
  width: 70%;
}

.hl .card-6__content-box .card-heading {
  font-size: 18px;
  line-height: 28px;
}

.hl .card-6__content-box .card-para {
  font-size: 15px;
  font-weight: 300;
  line-height: 23px;
  margin-top: 10px;
}

.hl .card-6__content-box .card-date {
  right: 0;
  display: block;
  bottom: 0;
  color: #9a9a9b;
  font-size: 12px;
}

.hl .l-card-block-6 {
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.hl .l-card-block-6:last-child {
  border-bottom: none;
}

/*room-page--type-2 */
.room-page--type-2 .page-block-1__right {
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
}

.room-page--type-2 .hl.block-5 {
  padding: 50px 0;
}

.room-page--type-2 .page-block {
  margin-top: 0px;
}

.room-page--type-2 .datepicker-card {
  text-align: center;
}

.room-page--type-2 .container {
  height: auto;
}

.room-page--type-2 .hl.card-6 {
  border-right: 1px solid #eee;
}

.room-page--type-2 .col-md-4:last-child .hl.card-6 {
  border: none;
}

.room-page--type-2 .hl .card-6__content-box {
  padding: 0;
}

.room-page--type-2 .hl .card-6__img-box {
  position: relative;
  top: auto;
  left: auto;
  margin: auto;
  width: auto;
  height: auto;
}

.room-page--type-2 .hl .card-6__img-box img {
  width: 46px;
  height: 46px;
}

.room-page--type-2 .hl .card-6__content-box-inner {
  width: 100%;
  margin-top: 16px;
  padding: 0 30px;
}

.room-page--type-2 .hl .card-date {
  position: relative;
  bottom: auto;
  right: auto;
}

.room-page--type-2 .hl .feature-list__item {
  width: 24%;
  text-align: center;
}

.room-page--type-2 .hl .card-user-name {
  font-size: 14px;
  font-family: "Libre Baskerville", "Roboto", sans-serif;
  color: #000;
  letter-spacing: 0;
  margin-left: 10px;
}

.room-page--type-2 .hl .card-para {
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  margin: 15px 0;
}

.room-page--type-2 .hl .card-heading {
  font-size: 20px;
}

@media (max-width: 992px) {
  .hl .card-block-3__content-box {
    width: 100%;
    padding: 36px 20px 20px;
  }

  .hl .card-block-3__img-box {
    float: left;
    width: 100%;
  }

  .hl.card-block-3 {
    max-height: initial;
  }

  .page-block-1 .page-block-1__right {
    width: 100%;
    margin-left: 0;
    padding: 20px;
  }

  .page-block-1 .page-block-1__left {
    width: 100%;
    position: relative;
    left: 0;
    top: auto;
    z-index: 11;
    height: 100%;
  }
}

@media (max-width: 768px) {
  .hl .feature-list .feature-list__item {
    width: 49%;
  }

  .room-page--type-2 .hl.card-6 {
    border: none;
  }
}

@media (max-width: 600px) {
  .hl .card-6__content-box {
    padding-left: 0;
    padding-top: 15px;
  }

  .hl .card-6__img-box {
    position: relative;
    display: inline-block;
  }

  .hl .card-6__content-box .card-date {
    position: relative;
  }

  .hl .card-6__content-box .card-6__content-box-inner {
    width: 100%;
  }
}

@media (max-width: 540px) {
  .datepicker-card .check-in-date:before {
    /* content: url(../images/arrow-forward-white.svg); */
    position: absolute;
    right: 0;
    left: 0;
    bottom: -13px;
    top: auto;
    margin: auto;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .datepicker-card .check-room-date label {
    display: block;
    padding: 29px 0 33px 0;
    color: #ad9782;
  }

  .datepicker-card .check-room-date {
    height: 100px;
    width: 100%;
  }

  .datepicker-card .check-room-date input {
    padding-top: 20px;
  }

  .datepicker-card .select-date-rooms {
    display: block;
  }

  .datepicker-card .availability-btn {
    width: 100%;
  }
}

.about-page-section-1 {
  padding: 80px 0 30px 0;
}

.small-heading {
  font-size: 24px;
}

.about-block-1 {
  margin-bottom: 70px;
}

.about-block-3 img {
  width: 100%;
}

.about-block-3 .simple-text-block-1 p {
  margin: 50px 0;
}

.about-page .simple-text-block-1 p {
  color: #444;
  line-height: 27px;
}

.about-page .hl.heading {
  font-weight: 600;
  margin-bottom: 20px;
}

.date-tag {
  background: #000;
  color: #fff;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .5px;
}

.hl-card__image-box {
  position: relative;
  height: 300px;
  overflow: hidden;
}

.hl-card__image-box .date-tag {
  position: absolute;
  left: 0;
  top: 20px;
  z-index: 11;
}

.fix-height-image-box {
  max-height: 250px;
  overflow: hidden;
}

.tabs-block-1 {
  padding: 12px 0;
}

.tabs-block-1 ul {
  text-align: center;
}

.tabs-block-1 li {
  display: inline-block;
  text-align: center;
  margin-right: 50px;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #6b6b6b;
}

.tabs-block-1 li:last-child {
  margin: 0;
}

.tabs-block-1 a {
  position: relative;
}

.tabs-block-1 a:after {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  -ms-transition: transform .3s ease;
  -o-transition: transform .3s ease;
  transition: transform .3s ease;
  content: "";
  width: 50%;
  height: 1px;
  transform-origin: 50% 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -5px;
}

.tabs-block-1 a.active {
  color: #000;
  font-weight: 400;
}

.tabs-block-1 a.active:after {
  background: #ad9782;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
}

.tabs-content-1 li {
  display: inline-block;
  width: 33%;
  padding: 15px;
}

.event-page .section-2 {
  margin-bottom: 80px;
}

.event-page .simple-text-block-1--style-2 {
  margin: 0;
}

.event-page .simple-text-block-1 h3 {
  font-size: 24px;
}

.event-page .simple-text-block-1 p {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.event-page .tabs-block-1 {
  margin-top: 50px;
}

.event-page .hl-card__content-box {
  padding: 20px 20px 30px;
}

.gallery-page .tabs-content-1 li {
  padding: 5px;
}

.gallery-page .simple-text-block-1 {
  margin-top: 80px;
}

.img-box img {
  width: 100%;
}

.small-banner .banner-1__hero-container {
  bottom: auto;
  top: 34%;
}

.service-page .simple-text-block-1--style-2 {
  margin-top: 80px;
  margin-bottom: 100px;
}

.service-page .hl-card__content-box h3 {
  font-size: 23px;
}

.service-page .hl-card {
  box-shadow: none;
  margin-bottom: 30px;
}

.service-page .hl-card__content-box {
  padding-left: 0;
  padding-top: 10px;
}

.service-page .service-card-container {
  margin-bottom: 50px;
}

.service-page .hl-card__content-box p {
  margin: 13px 0 20px 0;
}

.service-page.service-page--style-2 .hl-card__content-box h3 {
  font-size: 24px;
  line-height: 20px;
  margin-top: 0;
}

.service-page.service-page--style-2 .hl-card__image-box {
  float: left;
  width: 256px;
  height: 220px;
}

.service-page.service-page--style-2 .hl-card__content-box {
  padding: 10px 20px 30px 30px;
  float: left;
  position: relative;
  width: calc(100% - 256px);
}

.service-page.service-page--style-2 .hl-card__content-box p {
  margin: 20px 0 22px 0;
}

.service-page.service-page--style-2 .hl-link {
  position: absolute;
  bottom: 0;
  left: 30px;
}

.service-page.service-page--style-2 .hl-card:after {
  content: "";
  display: table;
  clear: both;
}

.service-page.service-page--style-2 .hl-card {
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.l-right-side-panel {
  padding: 20px 30px 10px 30px;
  border: 1px solid #e4e4e4;
  background: #fcfcfc;
}

.right-side-panel .hl .card-6__img-box {
  width: 40px;
  height: 40px;
}

.right-side-panel .hl .card-6__img-box img {
  width: 40px;
  height: 40px;
}

.right-side-panel .hl .card-6__content-box {
  padding-left: 53px;
  padding-top: 0;
  margin-bottom: 15px;
}

.right-side-panel .hl .card-6__content-box-inner {
  width: 100%;
}

.right-side-panel .hl.card-6 {
  margin-bottom: 15px;
  padding-bottom: 0px;
  border-bottom: 1px solid #eee;
}

.right-side-panel .hl.card-6:last-child {
  border: none;
}

.right-side-panel .hl .card-6__content-box .card-para {
  margin-top: 0;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
}

.right-side-panel .hl .card-6__content-box .card-user-name {
  font-size: 16px;
}

@media (max-width: 768px) {
  .service-page.service-page--style-2 .hl-card__image-box {
    float: none;
    width: 100%;
  }

  .service-page.service-page--style-2 .hl-card__content-box {
    padding-top: 28px;
    padding-left: 0px;
    width: 100%;
  }

  .service-page.service-page--style-2 .hl-link {
    position: relative;
    left: 0;
  }

  .service-page.service-page--style-2 .hl-card {
    margin-bottom: 30px;
    padding-bottom: 0px;
  }
}

.blog-card-container {
  margin-bottom: 80px;
}

.blog-page .section-2 {
  margin-bottom: 80px;
}

.blog-page .hl-card__content-box h3 {
  font-size: 24px;
  line-height: 35px;
}

.blog-page .hl-btn {
  padding: 12px 30px;
}

.blog-page .hl.cards-block-1 {
  margin-bottom: 30px;
}

.blog-page .hl-card__image-box {
  max-height: 250px;
  overflow: hidden;
}

.hint-2 {
  font-size: 13px;
  color: #ccc;
}

/* blog details */
.blog-detail-page .section-2 {
  height: auto;
}

.blog-page-container .social-icon a {
  font-size: 20px;
  margin-right: 10px;
  background: #fff;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 8px;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15); */
  border: 1px solid #f5f5f5;
}

.blog-page-container .social-icon .fb {
  color: #3b5998;
}

.blog-page-container .social-icon .tw {
  color: #598dca;
}

.blog-page-container .social-icon .quora {
  color: #b92b27;
}

.blog-page-container .social-icon .pin {
  color: #bd081c;
}

.blog-heading {
  line-height: 56px;
  padding-right: 12%;
}

.blog-detail__header {
  padding: 70px 0 0 0;
  min-height: 400px;
}

.blog-detail__header .blog-heading {
  margin: 15px 0;
}

.blog-detail__header .container {
  width: 900px;
  margin: auto;
}

.blog-label {
  padding: 4px 10px;
  background: #ad9782;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  margin: 5px 0 0;
  display: inline-block;
}

.blog-detail__content .container {
  width: 900px;
  margin: auto;
  margin-bottom: 60px;
}

.blog-detail__content .img-box {
  max-height: 400px;
  overflow: hidden;
  margin: 5px 60px;
  margin-top: 60px;
}

.blog-detail__content .img-box.featured {
  margin: 5px 0px;
}

.blog-detail__content span {
  display: block;
  text-align: center;
}

.blog-detail__content a,
.blog-detail__content p,
.blog-detail__content ul,
.blog-detail__content span {
  font-size: 19px;
  line-height: 40px;
  color: #333;
  font-family: "merriweather";
}

.blog-detail__content p,
.blog-detail__content ul {
  margin: 0px 60px;
  margin-top: 30px;
}

.blog-detail__content .image-caption {
  margin-left: 0;
  font-size: 14px;
  margin-top: 0px;
}

.blog-detail__content a {
  color: #b18459;
}

.blog-detail__content li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.blog-detail__content li:before {
  content: "";
  background: #333;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 20px;
  left: 0;
  top: 0;
  z-index: 1;
  bottom: 0;
  margin: auto;
}

.blog-detail__content .quote-text {
  color: #828282 !important;
  font-weight: 100;
  font-style: italic;
  border-left: 1px solid #ccc;
  padding-left: 16px;
}

.blog-detail__content .ques-text {
  font-size: 22px !important;
  margin-top: 35px !important;
}

.blog-detail__content .ques-ans {
  margin-top: 15px;
}

.recent-blog-container .hl-card__image-box {
  height: 150px;
}

.recent-blog-container .hl-card__content-box h3 {
  font-size: 21px;
  line-height: 28px;
}

.recent-blog-container .hl-card__content-box p {
  /* margin: 10px 0 12px 0; */
}

.recent-blog-container .blog-heading {
  padding-right: 0;
}

.l-rbc {
  margin: 100px 0;
}

.hl-link-2 {
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ad9782;
  font-size: 13px;
}

/* profile box  */
.profile-box-2__img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100px;
  float: left;
}

.profile-box-2__img img {
  width: 100%;
}

.profile-box-2__content {
  float: left;
  width: calc(100% - 60px);
  padding-left: 15px;
  position: relative;
}

.profile-box-2__content .btn {
  padding: 3px 16px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.pb-heading {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  color: #222;
}

.pb-text {
  margin: 3px 0 2px;
  color: #999;
  font-size: 14px;
  letter-spacing: .2px;
}

.pb-text-2 {
  margin: 0;
}

.l-profile-box-2 {
  margin-bottom: 40px;
}

.contact-page textarea.form-control {
  height: auto;
}

.contact-section {
  margin-top: 200px;
}

.contact-heading {
  margin: 80px 0;
}

.contact-heading p {
  color: #ad9782;
}

.contact-form-wrapper {
  margin: 100px 0;
}

.map-container {
  height: 500px;
  width: 100%;
  z-index: 1;
}

#map {
  width: 100%;
  height: 100%;
}

.contact-section-1 {
  height: 500px;
  overflow: hidden;
}

.contact-page .hero-text {
  margin: 75px 0;
}

.contact-page .hero-text span {
  display: block;
}

.contact-page .hero-text__heading {
  font-size: 38px;
  font-weight: 500;
  display: block;
  margin: 20px 0 13px 0;
}

.contact-page .hero-text__subheading {
  font-size: 14px;
  display: block;
  font-weight: 400;
}

.contact-form .form-control {
  box-shadow: none;
  background: #f7f7f7;
}

.contact-form label {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contact-details p {
  font-size: 13px;
  line-height: 22px;
}

.contact-details h3 {
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 0;
}

.contact-details .f-form {
  font-size: 11px;
  text-transform: uppercase;
  color: #9e9e9e;
}

.block h3 {
  font-size: 12px;
}

.contact-form .form-group {
  margin-bottom: 30px;
}

.contact-form .btn {
  width: 168px;
}

textarea.form-control {
  padding: 15px;
  resize: none;
}

.rooms-page .hl-card-block {
  margin-bottom: 30px;
}

.rooms-page .simple-text-block-1 {
  margin: 80px 0 50px 0;
}

.rooms-page .select-date-rooms {
  margin-bottom: 50px;
}

.rooms-rev-value {
  margin-left: 9px;
  color: #999;
}

.check-room-date {
  display: inline-block;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 254px;
}

.check-room-date input {
  border: none;
  background: transparent;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  padding-top: 18px;
  height: 100%;
}

.check-room-date input:focus {
  border: none;
  outline: 0;
  background: rgba(0, 0, 0, 0.05);
}

.check-room-date label {
  display: block;
  padding: 14px 0 30px 0;
  color: #ad9782;
}

.check-in-date:before {
  /* content: url(../images/arrow-forward-white.svg); */
  position: absolute;
  right: -15px;
  top: 19px;
  background: #ad9782;
  border-radius: 50px;
  width: 28px;
  height: 28px;
  padding: 7px;
  z-index: 1;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .rooms-page .check-in-date:before {
    /* content: url(../images/arrow-forward-white.svg); */
    position: absolute;
    right: 0;
    left: 0;
    bottom: -13px;
    top: auto;
    margin: auto;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .rooms-page .check-room-date label {
    display: block;
    padding: 29px 0 33px 0;
    color: #ad9782;
  }

  .rooms-page .check-room-date {
    height: 100px;
    width: 100%;
  }

  .rooms-page .check-room-date input {
    padding-top: 20px;
  }

  .hl.card-block-2 {
    max-height: initial;
  }
}

.profile-container {
  /* background: url(../images/banner/banner1.png) no-repeat; */
  background-size: cover;
  height: 600px;
  padding: 12% 0;
}

.profile-img,
.profile-details {
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  text-align: left;
  padding-right: 20px;
  color: #fff;
}

.profile-img h1,
.profile-details h1 {
  font-size: 50px;
  margin: 0 0 2px 0;
}

.profile-img {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
}

.p-action-links a,
.fav-icon-option a {
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: underline;
  padding-right: 10px;
  color: #fff;
}

.table,
.table-row {
  display: table;
  width: 100%;
  margin-bottom: 0;
}

.table .ui.checkbox {
  display: inline-block;
  margin-bottom: 0;
}

.table-head {
  background: #eee;
}

.table-body .table-row:nth-child(even) {
  background: #f7f7f7;
}

.th,
.td {
  display: table-cell;
  width: 10%;
}

.th {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .5px;
  padding: 15px 15px;
}

.td {
  font-weight: 400;
  font-size: 13px;
  padding: 15px 15px;
}

.booking-container {
  margin-bottom: 50px;
}

.swiper-slide-active .gradient-overlay {
  display: none;
}

.room-slider.swiper-container {
  padding: 20px 10px;
}

.banner-slider.swiper-container {
  height: 100%;
}

.banner-slider.swiper-container .banner-1__hero-container {
  position: relative;
  z-index: 11;
  padding: 20px 0px;
  padding-top: 26%;
  color: #fff;
  bottom: auto;
  left: 0;
  text-align: center;
}

.img-slider-4 .swiper-container {
  height: 100%;
}

.room-slider-2.swiper-container .swiper-slide {
  width: 992px;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  left: auto;
  right: 57px;
  background-size: 10px;
  background-color: rgba(0, 0, 0, 0.38);
  z-index: 111;
  width: 40px;
  height: 40px;
  border-radius: 32px;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
  background-size: 10px;
  /* opacity: .4; */
  background-color: rgba(0, 0, 0, 0.38);
  z-index: 111;
  width: 40px;
  height: 40px;
  border-radius: 32px;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.room-slider-2 img {
  height: 100%;
}

@media (max-width: 768px) {
  .section-2 {
    margin-top: 103px;
  }

  .hl .enquiry-list__item {
    float: left;
    border-right: 1px solid #f3f3f3;
    width: 50%;
    height: 72px;
    vertical-align: middle;
    position: relative;
  }

  .hl.enquiry-section {
    z-index: 11;
  }

  .hl.enquiry-section {
    max-width: 1170px;
    margin: auto;
    top: auto;
    position: relative;
    height: auto;
  }

  .simple-text-block-1 {
    width: 100%;
  }

  .slider-block-2__box-content-left {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
  }

  .slider-block-2__box-content-right {
    width: 100%;
    text-align: left;
  }

  .hl .card-block-2__img-box img,
  .hl .card-block-2__img-box {
    width: 100%;
  }

  .hl .card-block-2__content-box {
    width: 100%;
    padding: 20px 10px;
  }

  .hl .price-box {
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .card-block-2__footer-right {
    margin-top: 20px;
  }

  .enquiry-form {
    padding: 30px 0px;
  }

  .element1__img {
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
  }

  .text-block1 {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    margin-top: 20px;
  }

  .simple-text-block-1 p {
    padding: 0;
  }
}

.single-line-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  height: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 4px -5px 11px 0 rgba(0, 0, 0, 0.19);
  padding: 6px 50px;
}

.single-line-footer .share-btn {
  background: #ffffff;
  padding: 9px 30px 9px;
  vertical-align: middle;
  border: 1px solid #444;
}

.single-line-footer .share-btn .share-btn__text {
  color: #222;
  font-weight: 400;
}

.single-line-footer .share-btn__icon span {
  color: #222;
}

.single-line-footer__left {
  padding: 8px;
}

.imp-link {
  vertical-align: middle;
  display: inline-block;
  margin-left: 25px;
}

.imp-link a {
  margin-right: 20px;
  font-weight: 400;
  border-bottom: 1px solid #444;
}

.single-line-footer.dark {
  background: #232323;
}

.single-line-footer.dark .imp-link a {
  color: #fff;
  border-color: #fff;
}

.single-line-footer.dark .share-btn {
  background: transparent;
  border-color: #fff;
}

.single-line-footer.dark .share-btn .share-btn__text {
  color: #fff;
}

.single-line-footer.dark .share-btn .share-btn__icon span {
  color: #fff;
}

.footer__heading {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  padding-top: 0;
}

.hl.footer {
  padding: 100px 0 !important;
  background: #232323;
  color: #fff;
}

.hl.footer li {
  padding: 10px 0px;
  letter-spacing: .2px;
}

.footer__logo {
  margin: 8px 0;
  text-transform: uppercase;
  font-weight: 700;
}

.footer__left-content {
  line-height: 25px;
}

.social-icon {
  padding: 8px 0 !important;
}

.social-icon a {
  text-decoration: none;
  padding: 1px 5px;
  display: inline-block;
  font-size: 16px;
  padding-top: 4px;
}

.small-footer {
  background: #000;
  color: #fff;
}

.small-footer p {
  padding: 10px;
  padding-right: 94px;
}

.content-container {
  width: 100%;
  padding: 20px;
  background: #fff;
  margin: 30px 0 50px;
}

.hl-form-group label {
  font-weight: 500;
  color: #949494;
  text-transform: uppercase;
  font-family: 'Work Sans', sans-serif;
  font-size: 10px;
  color: #949494;
  letter-spacing: 0px;
  line-height: 14px;
  margin-bottom: 1px;
}

.hl-form-control {
  background: #EAEAEA;
  border-radius: 4px;
  border: 0;
  padding: 0px 10px;
  height: 40px;
  box-shadow: none;
  border-color: none;
}

.hl-form-control.error {
  border: solid 1px #F95C63;
  background: #fff;
}

.hl-form-control:focus {
  border-color: none;
  box-shadow: none;
}

.hl-heading-one {
  font-size: 24px;
  color: #2B2B2B;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0px 0 20px 0;
}

.ui.checkbox {
  display: block;
  margin-bottom: 15px;
}

.ui.checkbox label,
.ui.checkbox+label,
label.text-style1 {
  color: #2B2B2B;
  font-weight: 400;
}

label.text-style1,
label.text-style1 a {
  text-transform: inherit;
  font-weight: 400;
}

.ui.checkbox label a,
.ui.checkbox+label a,
label.text-style1 a {
  font-size: 12px;
  color: #ad9782;
  letter-spacing: 0;
  line-height: 18px;
  border-bottom: 1px solid #ad9782;
  margin-left: 5px;
}

.btn.btn-farwide-default {
  font-size: 16px;
  color: #2B2B2B;
  letter-spacing: 0;
  line-height: 24px;
  border: 0;
  border-bottom: #2b2b2b solid 1px;
  border-radius: 0;
  padding: 0px;
  text-transform: uppercase;
}

.btn.btn-farwide-default:hover,
.btn.btn-farwide-default:focus,
.btn.btn-farwide-default:active {
  background-color: initial;
  outline: 0;
  box-shadow: none;
}

.signup-fb {
  /* background: url(../images/icon-signup-fb.svg) top right no-repeat; */
  padding: 0px 27px 1px 0;
  font-size: 12px;
  color: #3b5998;
  letter-spacing: 0;
  line-height: 18px;
  display: inline-block;
  border-bottom: solid 1px #3b5998;
}

.signup-or {
  font-size: 12px;
  color: #2B2B2B;
  letter-spacing: 0;
  display: inline-block;
  margin-bottom: 8px;
}

.message {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0;
}

.message.message-error {
  color: #F95C63;
}

.text-style1 a:hover {
  text-decoration: none;
}

.toggle-text {
  position: absolute;
  top: 1rem;
  z-index: 10;
  color: #fff;
  margin-left: 5rem;
  cursor: pointer;
  font-size: 10px;
}

.ui.toggle.checkbox.checked .toggle-text-no {
  color: #2B2B2B;
}

.ui.toggle.checkbox.checked .toggle-text-yes {
  color: #FFFFFF;
}

.toggle-text-no {
  margin-left: 5rem;
  left: 0;
}

.toggle-text-yes {
  margin-left: 5rem;
  left: 10rem;
  color: #2B2B2B;
}

@-moz-document url-prefix() {
  .hl-form-control {
    padding: 0px 10px;
    height: 40px;
  }

  .hl-search-bar .ui.dropdown.hl-first-dropdown {
    line-height: 14px;
  }
}

.search-input-box input::-webkit-input-placeholder {
  color: #949494 !important;
  font-weight: normal;
}

.search-input-box input::-moz-input-placeholder {
  color: #949494 !important;
  font-weight: normal;
}

.search-input-box input::-ms-input-placeholder {
  color: #949494 !important;
  font-weight: normal;
}

.search-input-box input::-input-placeholder {
  color: #949494 !important;
  font-weight: normal;
}

.login-modal .modal-backdrop {
  background-color: rgba(43, 43, 43, 0.7);
}

.login-modal .modal-backdrop.in {
  filter: alpha(opacity=100);
  opacity: 1;
}

.login-modal .modal-dialog {
  background: #fff;
  border-radius: 0;
}

.login-modal .modal-content {
  height: 100%;
  border-radius: 0;
  overflow: hidden;
}

.login-modal .search-modal {
  padding: 20px 20px 30px;
  border-bottom: 1px solid #eaeaea;
}

.login-modal .search__heading {
  color: #2B2B2B;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 0;
}

.login-modal .search-section {
  margin-top: 23px;
}

.login-modal .steps {
  text-transform: uppercase;
  color: #949494;
  font-size: 12px;
}

.login-modal .search__btn {
  padding: 9px 20px;
  display: block;
  text-align: center;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.login-modal .search__btn:hover,
.login-modal .search__btn:focus {
  color: #fff;
}

.login-modal .search__btn.black {
  background: #2b2b2b;
}

.login-modal .or-seperate {
  margin: 13px;
  color: #949494;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
}

.login-modal .back-btn {
  color: #949494;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}

.login-modal .search-modal__top-header {
  position: relative;
}

.login-modal .state-select {
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  color: #2b2b2b;
  border: none;
  box-shadow: none;
  /* background: #eaeaea url(../images/zero-state-icon/arrow-down.black.svg) no-repeat; */
  background-position: 95% center;
  line-height: 22px;
}

.login-modal .state-select:focus {
  box-shadow: none;
}

.login-modal .state-select option {
  padding: 5px 0;
  border-bottom: 2px solid #000;
  background: #fff;
}

.login-modal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.login-modal .section__heading {
  font-weight: 500;
  font-size: 14px;
  color: #2b2b2b;
  background: #eaeaea;
  padding: 8px 0 8px 22px;
  margin: 15px -16px 10px;
  line-height: 24px;
}

.login-modal .search-tags__list {
  margin-top: 2px;
}

.login-modal .search-tags__list li {
  background: #4c4c4c;
  padding: 8px 9px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
  border-radius: 2px;
  margin: 3px 1px;
  cursor: pointer;
}

.login-modal .search-tags__list li.active {
  background-color: #26C77A;
}

.popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(45, 45, 45, 0.91);
  z-index: 111111;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease, visibility 0.2s ease;
  -moz-transition: opacity 0.2s ease, visibility 0.2s ease;
  -ms-transition: opacity 0.2s ease, visibility 0.2s ease;
  -o-transition: opacity 0.2s ease, visibility 0.2s ease;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.popup-container {
  width: 268px;
  height: 236px;
  border-radius: 10px;
  background: #fff;
  position: fixed;
  padding: 20px;
  top: 0;
  z-index: 111111;
  text-align: center;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease, visibility 0.2s ease;
  -moz-transition: opacity 0.2s ease, visibility 0.2s ease;
  -ms-transition: opacity 0.2s ease, visibility 0.2s ease;
  -o-transition: opacity 0.2s ease, visibility 0.2s ease;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.is-reveal .popup-wrapper,
.is-reveal .popup-container {
  opacity: 1;
  visibility: visible;
}

.popup-container h1 {
  font-size: 24px;
  margin-top: 0;
  font-weight: 600;
}

.popup-container p {
  margin: 8px 0 25px 0;
  color: #2B2B2B;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.popup-container .hl-btn {
  display: block;
  padding: 10px 30px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.login-modal .dismiss-modal {
  width: 47px;
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 8px;
  z-index: 11;
  padding: 15px;
}

.login-modal .form-control {
  border: 1px solid #eaeaea;
  box-shadow: none;
  border-radius: 4px;
}

.login-modal .form-control:focus {
  box-shadow: none;
  border-color: #eaeaea;
}

.hl-btn:focus,
.hl-btn:active {
  outline: 0;
  box-shadow: none;
}

.login-modal .modal-dialog {
  position: relative;
  z-index: 111;
}

body {
  padding-right: 0 !important;
}

.login-modal:after {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(43, 43, 43, 0);
}

/*new css*/
.login-modal .search__btn img {
  margin-right: 9px;
  position: relative;
  top: -1px;
}

.login-modal .search__btn:focus,
.login-modal .search__btn:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .login-modal.modal {
    padding: 0 !important;
  }

  .login-modal.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .login-modal .modal-dialog {
    z-index: 11;
  }

  .login-modal.modal-dialog {
    height: 100% !important;
    overflow: auto;
  }

  .switch-dots {
    margin: 20px 0px 3px;
  }
}

@media (min-width: 769px) {
  .login-modal .modal-dialog {
    margin: 0px auto;
    max-width: 779px;
    height: auto;
  }

  .login-modal.modal {
    text-align: center;
    padding: 0 !important;
  }

  .login-modal.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .login-modal .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
  }

  .desktop-hide {
    display: none;
  }
}

.popup-login-container,
.popup-signup-container {
  padding: 3.41% 3.67%;
  height: 100%;
}

.login-popup-header {
  text-align: center;
  font-weight: 600;
  line-height: 32px;
  font-size: 22px;
  margin: 0;
}

.lgn-header {
  color: #2B2B2B;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: normal;
  margin: 0;
  margin-bottom: 25px;
}

.lgn-left-section {
  padding: 17% 0 18% 0;
  background: #eaeaea;
  height: 100%;
  width: 50%;
  text-align: center;
  float: left;
}

.lgn-right-section {
  width: 50%;
  height: 100%;
  text-align: center;
  float: left;
  padding: 0 0 0 44px;
}

.main-login-container {
  margin-top: 30px;
  height: 83%;
}

.sl-btn {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  padding: 10px 72px;
  border: none;
}

.sl-btn.black {
  background: #2b2b2b;
}

.sl-btn.fb-color {
  background: #3B5998;
}

.btn.sl-btn.active.focus,
.btn.sl-btn.active:focus,
.btn.sl-btn.focus,
.btn.sl-btn:active.focus,
.btn.sl-btn:active:focus,
.btn.sl-btn:focus {
  color: #fff !important;
  border: none;
  box-shadow: none;
  outline: 0;
}

.lgn-right-section .sl-btn.fb-btn {
  width: 100%;
  padding: 10px 0;
}

.lgn-right-section .lgn-header {
  margin-bottom: 16px;
}

.sl-btn.btn.focus,
.btn.sl-btn:focus,
.btn.sl-btn:hover {
  color: #fff;
}

.small-seperator {
  color: #949494;
  font-size: 10px;
  line-height: 20px;
  margin: 8px 0;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
}

.login-modal .form-group {
  text-align: left;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 17px;
}

.login-modal .hl-form-control {
  background: #EAEAEA;
  border-radius: 4px;
  border: 0;
  padding: 0px 10px;
  height: 40px;
  box-shadow: none;
  border-color: none;
}

.login-modal .hl-form-control.error {
  border: solid 1px #F95C63;
  background: #fff;
}

.login-modal .hl-form-control:focus {
  border-color: none;
  box-shadow: none;
}

.login-modal .form-group label {
  color: #949494;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
}

.login-modal form .sl-btn {
  margin: 0 auto;
  margin-top: 15px;
}

.login-modal label {
  margin-bottom: auto;
}

.message {
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.message.message-error {
  color: #F95C63;
}

.clearfix {
  clear: both;
}

.popup-signup-container .login-popup-header {
  text-align: left;
  margin-bottom: 24px;
}

.popup-signup-container .small-seperator {
  margin: 20px 0;
}

.popup-signup-container .checkbox label {
  color: #2B2B2B;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
}

.popup-signup-container .checkbox {
  display: block;
}

.popup-signup-container .ui.checkbox .box,
.popup-signup-container .ui.checkbox label {
  padding-left: 24px;
}

.popup-signup-container .ui.checkbox .box:before,
.popup-signup-container .ui.checkbox label:before {
  border-radius: 0;
  border: 1px solid #2b2b2b;
  width: 16px;
  height: 16px;
  background: transparent;
  box-shadow: none;
}

.popup-signup-container .ui.checkbox input:checked~.box:before,
.popup-signup-container .ui.checkbox input:checked~label:before {
  border: 1px solid #2b2b2b;
  background: transparent;
}

.popup-signup-container .ui.checkbox .box:after,
.popup-signup-container .ui.checkbox label:after {
  font-size: 12px;
  top: -1px;
  width: 16px;
  height: 16px;
  color: #2b2b2b;
  font-weight: 600;
}

.big-btn.sl-btn {
  padding: 13px 121px;
  height: 48px;
}

.popup-signup-container .checkbox label a,
.al-acc-btn a {
  font-size: 12px;
  color: #2b2b2b;
  text-decoration: underline;
  border-bottom: none;
}

.al-acc-btn {
  color: #2B2B2B;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  display: inline-block;
  padding-left: 19px;
  position: relative;
  top: 5px;
}

.al-acc-btn a {
  margin-left: 7px;
}

.popup-signup-container .ui.checkbox {
  margin: 0;
}

.popup-signup-container .ui.checkbox input.hidden+label {
  margin-bottom: 8px;
  display: inline-block;
}

.fade-scale {
  transform: scale(1.4);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

.in .fade-scale {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  -webkit-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

.modal.in .modal-dialog {
  overflow: auto;
}

@media (min-width: 992px) {
  .no-pad-right.no-pad-right {
    padding-right: 0 !important;
  }
}

@media (max-width: 992px) {

  .popup-login-container,
  .popup-signup-container {
    height: auto !important;
    padding: 40px 30px;
  }

  .login-modal .modal-dialog {
    height: auto !important;
    width: 100%;
    max-width: 767px;
  }

  .login-modal .modal-content {
    overflow: auto;
  }
}

@media (max-width: 768px) {

  .lgn-right-section,
  .lgn-left-section {
    width: 100%;
    padding: 0;
    padding-bottom: 30px;
    float: none;
  }

  .lgn-left-section {
    padding: 7% 0 6% 0;
    margin-bottom: 20px;
    height: auto !important;
    margin-left: -30px;
    padding-left: 30px;
    padding-right: 30px;
    width: calc(100% + 60px);
  }

  .lgn-right-section {
    height: auto !important;
  }

  .popup-login-container,
  .popup-signup-container,
  .login-modal .modal-dialog {
    height: 100% !important;
  }

  .popup-login-container .sl-btn {
    width: 100%;
  }

  .big-btn.sl-btn {
    padding: 13px 0;
    width: 100%;
    text-align: center;
  }

  .al-acc-btn {
    margin: 20px 0;
  }

  .popup-signup-container .sl-btn {
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }

  .login-modal,
  .popup-signup-container .login-popup-header {
    text-align: center;
  }

  .login-modal .dismiss-modal {
    right: 20px;
    top: 3px;
  }

  .ui.checkbox {
    text-align: left;
  }
}

.home-icon {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 1111;
  font-size: 23px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}

.login-section {
  height: 100%;
}

.login-link.login-link {
  font-weight: 500;
  font-size: 15px;
  color: #ad9782 !important;
  letter-spacing: .5px;
}

.login-page h1 {
  font-weight: 100;
  color: #222;
  font-size: 36px;
  margin-bottom: 28px;
}

.login-page .swiper-container {
  z-index: 111;
}

.login-page .hl-card--type-2 h3 {
  color: #000;
}

.login-page .hl-card__content-box p {
  color: #000;
}

.login-page .profile-detail__image {
  margin-bottom: 25px;
}

.login-page .profile-detail__image img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

.login-page .profile-detail__image h4 {
  color: #000;
  font-size: 16px;
  margin: 10px 0;
}

.left-account-line {
  color: #fff;
  z-index: 111;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 0.5px;
}

.left-account-line a {
  text-decoration: underline;
  margin-left: 2px;
  text-transform: capitalize;
  font-weight: 400;
}

.login-section__left {
  position: relative;
  /* background: url(../images/new/5.jpg) no-repeat; */
  background-size: cover;
}

.login-section__left img {
  width: 100%;
}

.login-section__left .gradient-overlay {
  background: -moz-linear-gradient(0deg, rgba(10, 10, 10, 0.21) 41%, rgba(255, 255, 255, 0) 76%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(10, 10, 10, 0.21) 41%, rgba(255, 255, 255, 0) 76%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, rgba(10, 10, 10, 0.21) 41%, rgba(255, 255, 255, 0) 76%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1);
}

.login-section__left .hl-card--type-2 {
  height: 470px;
  padding: 30px 30px 30px 30px;
  position: relative;
  width: 323px;
  background: #bbc1c1;
  margin: auto;
  margin-top: 7%;
  border: 11px solid #000000;
}

.login-section__left .hl-card__content-box {
  padding: 0;
}

.login-section__left .hl-card--type-2 .hl-card__footer {
  box-sizing: border-box;
}

.login-section__left .swiper-container {
  z-index: 111;
}

.login-section__right-box {
  width: 500px;
  margin: auto;
  margin-top: 12%;
}

.login-heading-section {
  margin-bottom: 30px;
}

.login-heading-section h1 {
  margin-bottom: 12px;
}

.login-heading-section p {
  font-size: 16px;
  color: #a0a0a0;
  font-weight: 100;
  line-height: 24px;
}

.login-box .form-control {
  height: 50px;
}

.login-box label {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.login-box .hl-btn {
  padding: 16px 55px;
  border-radius: 100px;
}

.login-box .ui.checkbox {
  margin: 25px 0;
}

.hl-btn.black {
  background: #000;
  border-color: #000;
}

.login-section__other-links {
  margin: 42px 0px;
}

.login-section__other-link {
  font-size: 13px;
  margin-right: 20px;
  color: #484848;
  border-right: 1px solid #eee;
  padding-right: 20px;
}

.login-section__other-link:hover {
  text-decoration: underline;
}

.login-section__other-link:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

@media (min-width: 768px) {
  .login-page {
    height: 100%;
    overflow: hidden;
  }

  .login-section__left {
    width: 40%;
    float: left;
    height: 100%;
  }

  .login-section__right {
    width: 60%;
    float: left;
  }

  .left-account-line {
    position: absolute;
    bottom: 20px;
  }
}

@media (max-width: 768px) {
  .login-section__left {
    width: 100%;
    height: 84px;
  }

  .left-account-line {
    position: relative;
    z-index: 111;
    padding: 31px;
    padding: 35px;
    padding-left: 83px;
    font-size: 14px;
  }

  .login-section__right-box {
    width: 100%;
    margin: auto;
    margin-top: 0px;
    padding: 25px;
    padding-bottom: 100px;
  }

  .login-section__other-link {
    font-size: 11px;
    margin-right: 9px;
    padding-right: 9px;
  }

  .login-page h1 {
    font-size: 30px;
  }
}

.no-padding.no-padding {
  padding: 0 !important;
}

.dashboard {
  /* dashboard ui theme end */
  background: #e9ecf3;
  /* color */
  /* dropdown */
}

.dashboard .btn {
  padding: 12px 30px;
  border: none;
  cursor: pointer;
  min-width: 135px;
}

.dashboard .btn.btn-primary {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff;
}

.dashboard .btn.btn-success {
  background: #29c37c;
  background-image: -moz-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: -webkit-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: linear-gradient(to right, #18b16a 0%, #29c37c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#18b16a', endColorstr='#29c37c', GradientType=1);
  color: #fff;
}

.dashboard .btn.btn-info {
  background: #0fb9d4;
  background-image: -moz-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: -webkit-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: linear-gradient(to right, #0f9db3 0%, #0fb9d4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f9db3', endColorstr='#0fb9d4', GradientType=1);
  color: #fff;
}

.dashboard .btn.btn-secondary {
  background: #eaeaea;
  background-image: -moz-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: -webkit-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: linear-gradient(to right, #eaeaea 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#f1f1f1', GradientType=1);
}

.dashboard .btn.btn-danger {
  background: #f35454;
  background-image: -moz-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: -webkit-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: linear-gradient(to right, #e43d3d 0%, #f35454 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e43d3d', endColorstr='#f35454', GradientType=1);
  color: #fff;
}

.dashboard .btn.btn-warning {
  background: #ffcd56;
  background-image: -moz-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: -webkit-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: linear-gradient(to right, #f3bc3c 0%, #ffcd56 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3bc3c', endColorstr='#ffcd56', GradientType=1);
  color: #fff;
}

.dashboard .btn:hover,
.dashboard .btn:focus,
.dashboard .btn:active {
  border: none;
  box-shadow: none;
  outline: 0;
}

.dashboard .btn-primary {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff;
}

.dashboard .btn-success {
  background: #29c37c;
  background-image: -moz-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: -webkit-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: linear-gradient(to right, #18b16a 0%, #29c37c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#18b16a', endColorstr='#29c37c', GradientType=1);
  color: #fff;
}

.dashboard .btn-info {
  background: #0fb9d4;
  background-image: -moz-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: -webkit-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: linear-gradient(to right, #0f9db3 0%, #0fb9d4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f9db3', endColorstr='#0fb9d4', GradientType=1);
  color: #fff;
}

.dashboard .btn-secondary {
  background: #eaeaea;
  background-image: -moz-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: -webkit-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: linear-gradient(to right, #eaeaea 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#f1f1f1', GradientType=1);
}

.dashboard .btn-danger {
  background: #f35454;
  background-image: -moz-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: -webkit-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: linear-gradient(to right, #e43d3d 0%, #f35454 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e43d3d', endColorstr='#f35454', GradientType=1);
  color: #fff;
}

.dashboard .btn-warning {
  background: #ffcd56;
  background-image: -moz-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: -webkit-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: linear-gradient(to right, #f3bc3c 0%, #ffcd56 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3bc3c', endColorstr='#ffcd56', GradientType=1);
  color: #fff;
}

.dashboard .bg-primary {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff;
}

.dashboard .bg-success {
  background: #29c37c;
  background-image: -moz-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: -webkit-linear-gradient(left, #18b16a 0%, #29c37c 100%);
  background-image: linear-gradient(to right, #18b16a 0%, #29c37c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#18b16a', endColorstr='#29c37c', GradientType=1);
  color: #fff;
}

.dashboard .bg-info {
  background: #0fb9d4;
  background-image: -moz-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: -webkit-linear-gradient(left, #0f9db3 0%, #0fb9d4 100%);
  background-image: linear-gradient(to right, #0f9db3 0%, #0fb9d4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f9db3', endColorstr='#0fb9d4', GradientType=1);
  color: #fff;
}

.dashboard .bg-secondary {
  background: #eaeaea;
  background-image: -moz-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: -webkit-linear-gradient(left, #eaeaea 0%, #f1f1f1 100%);
  background-image: linear-gradient(to right, #eaeaea 0%, #f1f1f1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eaeaea', endColorstr='#f1f1f1', GradientType=1);
}

.dashboard .bg-danger {
  background: #f35454;
  background-image: -moz-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: -webkit-linear-gradient(left, #e43d3d 0%, #f35454 100%);
  background-image: linear-gradient(to right, #e43d3d 0%, #f35454 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e43d3d', endColorstr='#f35454', GradientType=1);
  color: #fff;
}

.dashboard .bg-warning {
  background: #ffcd56;
  background-image: -moz-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: -webkit-linear-gradient(left, #f3bc3c 0%, #ffcd56 100%);
  background-image: linear-gradient(to right, #f3bc3c 0%, #ffcd56 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3bc3c', endColorstr='#ffcd56', GradientType=1);
  color: #fff;
}

.dashboard .btn:focus {
  box-shadow: none !important;
}

.dashboard .progress-bar.thin {
  height: 6px;
}

.dashboard .ui.selection.dropdown {
  border-radius: 4px;
  padding: 12px 30px 12px 20px;
  border: none;
}

.dashboard .ui.selection.active.dropdown:hover {
  border-color: #f5f7fa;
  box-shadow: none;
}

.dashboard .ui.selection.active.dropdown .menu,
.dashboard .ui.selection.dropdown .menu {
  border-color: #f5f7fa;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  top: 0;
}

.dashboard .ui.dropdown.selected,
.dashboard .ui.dropdown .menu .selected.item {
  background: #f5f7fa;
  color: #4680ff;
}

.dashboard .ui.dropdown .menu>.item:hover {
  background: #f5f7fa;
}

.dashboard .ui.dropdown>.text {
  line-height: 21px;
}

.dashboard .ui.selection.dropdown>.search.icon,
.dashboard .ui.selection.dropdown>.delete.icon,
.dashboard .ui.selection.dropdown>.dropdown.icon {
  padding: 13px;
}

.dashboard .row {
  margin: 0;
}

.text-danger {
  color: #ff0018 !important;
}

.text-success {
  color: #14b93a !important;
}

.no-pad-bottom {
  padding-bottom: 0 !important;
}

.d-sidebar-left {
  width: 250px;
  height: 100%;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1111;
  background-color: #222c3c;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  -ms-transition: transform .3s ease;
  -o-transition: transform .3s ease;
  transition: transform .3s ease;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.d-sidebar-left .scrollbar-thumb {
  background: #111927 !important;
  border-radius: 0 !important;
}

.d-sidebar-left .scrollbar-track {
  background: #222c3c !important;
}

.d-sidebar-left__profile-box {
  padding: 20px 30px;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.d-sidebar-left__profile-box .info-card__img {
  color: #fff;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.d-sidebar-left__profile-box .info-card__content-name {
  color: #fff;
  font-size: 14px;
}

.d-sidebar-left__profile-box .info-card__content {
  vertical-align: middle;
}

.info-card__img.pImage40 {
  width: 40px;
  height: 40px;
}

.info-card__img.pImage40 img {
  width: 40px;
  width: 40px;
}

.d-profile-image {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.info-card__content-name {
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.profile-caret-icon {
  margin-left: 3px;
  color: #999;
}

.d-profile-name {
  color: #fff;
  padding-top: 11px;
  padding-left: 60px;
  font-size: 16px;
}

.d-sidebar-left__d-menu {
  padding: 20px 0px;
  color: #fff;
}

.d-menu-heading {
  color: #8895a9;
  padding-bottom: 0px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  padding-left: 30px;
}

.d-menu__list {
  margin-top: 10px;
}

.d-menu__list-item a {
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  color: #b7c0cd;
  font-size: 14px;
  letter-spacing: .5px;
  padding-left: 30px;
  border-left: 2px solid transparent;
}

.d-menu__list-item a:hover {
  color: #fff;
  border-color: #88a3cc;
  background: #1a212d;
}

.d-menu__list-item i.fa {
  margin-right: 15px;
}

.d-menu__list-item.active a {
  border-color: #88a3cc;
  background: #1a212d;
  background: rgba(255, 255, 255, 0.07059);
  color: #fff;
}

.d-main-container.slide-left {
  width: 100%;
  padding-left: 250px;
  -webkit-transition: padding-left .2s ease;
  -moz-transition: padding-left .2s ease;
  -ms-transition: padding-left .2s ease;
  -o-transition: padding-left .2s ease;
  transition: padding-left .2s ease;
}

.dashboard h1,
.dashboard h2,
.dashboard h3,
.dashboard h4,
.dashboard h5,
.dashboard h6 {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.color-grey {
  color: #999;
}

input,
textarea {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.d-header {
  height: 70px;
  padding: 6px 30px 0;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  position: relative;
  z-index: 111;
}

.d-header .inbox-left__search {
  margin: 7px;
}

.d-header .inbox-left__search input {
  font-size: 17px;
}

.d-header .btn {
  padding: 8px 30px;
  border: none;
  min-width: 120px;
  margin-right: 10px;
}

.d-tag-2 {
  width: 22px;
  height: 22px;
  border-radius: 32px;
  background: #ff7008;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding-top: 4px;
  text-align: center;
  position: absolute;
  top: -5px;
  right: -4px;
}

.d-header__left,
.d-header__right {
  padding-top: 18px;
}

.d-header__left h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}

.d-header__right {
  padding-top: 8px;
}

.d-header__right .d-profile-image {
  width: 35px;
  margin: 0px;
  height: 35px;
  position: relative;
  z-index: 1;
}

.d-header__right-icon {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.d-header__right-icon i.fa {
  font-size: 20px;
}

.my-scrollbar-noti,
.my-scrollbar-noti1 {
  height: calc(100% - 33px);
  width: 100%;
}

/* notification box */
.notification-box {
  position: absolute;
  height: 320px;
  text-align: left;
  background: #fff;
  box-shadow: 1px 4px 7px 0 rgba(0, 0, 0, 0.14);
  top: 44px;
  right: 0;
  width: 350px;
  z-index: 11;
  display: none;
}

.notification-box i.fa {
  font-size: 14px;
  margin-right: 15px;
  color: #60646d;
}

.notification-box a {
  display: block;
  padding: 14px 18px;
  border-bottom: 1px solid #f3f3f3;
}

.notification-box a:hover {
  background: #f5f7fa;
  color: #4680ff;
}

.notification-box a:hover i.fa {
  color: #4680ff;
}

.notification-box a:active {
  background: #eee;
}

.notification-box li:last-child a {
  border: none;
}

.notification-box .right-side-panel .hl .card-6__content-box {
  padding-left: 50px;
  margin-bottom: 0px;
}

.notification-box .right-side-panel .hl.card-6 {
  margin-bottom: 2px;
}

.notification-box .right-side-panel .hl .card-6__content-box .card-para {
  margin-top: 0;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  font-weight: 400;
  color: #5a5a5a;
}

.notification-box .right-side-panel .hl .card-6__content-box .card-user-name {
  font-size: 15px;
  margin-bottom: 4px;
}

.notification-box .info-card__content__name {
  font-size: 15px;
}

.notification-box .info-card__content__text {
  font-size: 13px;
}

.notification-box .info-card__img {
  width: 50px;
  height: 50px;
}

.small-text.message-time {
  position: relative;
  top: 0;
  right: auto;
  margin-top: 4px;
  display: inline-block;
}

.notification-box:before {
  content: "";
  position: absolute;
  right: 7px;
  top: -9px;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 9px solid #d9dde6;
}

.notification-box.account-box {
  width: 183px;
  top: 51px;
  height: auto;
}

.notification-box.account-box a {
  border: none;
}

.notification-box__heading {
  background: #e9ecf3;
  color: #0e0e0e;
  margin: 0;
  padding: 9px 16px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

.notification-box__heading a {
  display: inline-block;
  float: right;
  padding: 2px !important;
  font-size: 11px;
}

.notification-box__heading a:hover,
.notification-box__heading a:focus,
.notification-box__heading a:active {
  background: transparent;
}

.d-header__right-icon i:hover {
  color: #ad9782;
}

.d-tag {
  padding: 2px 4px;
  color: #fff;
  font-weight: 500;
  border-radius: 2px;
  font-size: 10px;
  text-transform: uppercase;
  margin-left: 5px;
}

.new-tag {
  background: #1892dc;
}

.update-tag {
  background: #dc7d18;
}

.counter-tag {
  background: #dc184d;
}

.d-box {
  padding: 35px 30px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 1px rgba(17, 59, 123, 0.06);
  margin-bottom: 20px;
  background: #fff;
}

.l-d-stats-box {
  padding: 20px 30px 30px;
}

.d-stats-box {
  background: #fff;
}

.d-stats-box .d-stats-box:last-child {
  border: none;
}

.d-stats-box .progress {
  background: #e9edf3;
  border-radius: 50px;
  height: 6px;
}

.progress-bar {
  height: 6px;
  background: #4680ff;
  background: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
}

.progress-bar.green {
  background: #54bb72;
}

.progress-bar.orange {
  background: #fdad3a;
}

.progress-bar.red {
  background: #f35454;
}

.d-stats-box__count {
  font-size: 60px;
  font-weight: 100;
  color: #222;
}

.d-stats-box__text {
  color: #292929;
  font-size: 16px;
  letter-spacing: .5px;
}

.stats-container-1 {
  background: #fff;
  padding: 26px 0;
}

.stats-container-1 .d-stats-box {
  border-right: 1px solid #f3f3f3;
  text-align: center;
}

.stats-container-2 {
  padding: 20px 30px;
  padding-bottom: 0px;
}

.stats-container-2 .d-stats-box {
  border-radius: 5px;
  box-shadow: 0px 1px 3px 1px rgba(17, 59, 123, 0.06);
  margin-bottom: 20px;
}

.stats-container-2 .d-stats-box__text {
  margin-bottom: 20px;
}

.l-chart-header {
  margin-bottom: 20px;
  border-bottom: 1px solid #f7f7f7;
  padding: 30px 30px;
}

.l-chart-footer__legend {
  margin-top: 20px;
  padding: 25px 0;
  border-bottom: 1px solid #f7f7f7;
}

.d-stats__chart-body {
  padding: 0 20px;
}

.legends-box__list {
  margin: 0;
}

.legend-box__list-item {
  display: inline-block;
  width: 49%;
}

.legend-circle {
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 10px;
  position: relative;
  top: 1px;
  margin: 0 8px;
}

.legend-circle.blue {
  border: 2px solid #4680ff;
  background: #4680ff;
}

.legend-circle.red {
  border: 2px solid #ff5454;
  background: #ff5454;
}

.legend-text {
  color: #444;
  font-size: 13px;
  letter-spacing: .4px;
}

.chart-header__text span {
  color: #9a9a9a;
  font-size: 13px;
}

.chart-heading {
  font-size: 18px;
  margin-bottom: 3px;
  margin-top: 0;
}

.chart-header__action-box i.fa {
  padding: 0 10px;
  cursor: pointer;
  font-size: 16px;
  color: #6f6f6f;
}

.chart-stats__list-item {
  margin-bottom: 30px;
}

.chart-stats__list-item p {
  margin-bottom: 9px;
  font-size: 13px;
  color: #888;
}

.chart-stats__list-item .progress-bar {
  height: 10px;
}

.l-chart-stats {
  background: #fbfbfb;
  padding: 30px;
}

.l-chart-stats .chart-heading {
  margin-bottom: 26px;
}

/* profile-page */
.profile-dash .d-main-container {
  margin-left: 0;
  width: 100%;
}

.profile-dash .big-container,
.profile-dash .d-main-container,
.profile-dash .container {
  height: 100%;
}

.left-profile-tabs {
  width: 300px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 15px;
  height: 100%;
  padding: 39px 20px;
}

.left-profile-tabs li a {
  display: inline-block;
  padding: 10px 10px;
  width: 100%;
  color: #576179;
  margin-bottom: 3px;
}

.left-profile-tabs li i.fa {
  margin-right: 20px;
}

.left-profile-tabs .d-sidebar-left__profile-box {
  padding: 9px 30px 20px;
}

.left-profile-tabs .d-profile-name {
  color: #222;
  padding-top: 0px;
  padding-left: 64px;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 10px;
}

.left-profile-tabs .d-profile-name span {
  font-size: 13px;
  color: #585858;
}

.left-profile-tabs .d-profile-image {
  width: 80px;
  margin: 0;
  height: 80px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.left-profile-tabs ul {
  margin-top: 11px;
}

.left-profile-tabs .d-menu-heading {
  margin: 15px 0px 15px;
  padding: 0;
}

.left-profile-tabs li.active a {
  background: #2e6cf2;
  background: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  border-radius: 4px;
  color: #fff;
}

.left-profile-tabs .p-social-icons {
  padding: 0 12px 12px 64px;
  font-size: 14px;
  color: #222;
}

.left-profile-tabs .p-social-icons i.fa {
  margin-right: 10px;
}

.profile-main-container {
  margin-left: 300px;
  width: calc(100% - 300px);
}

.d-form label {
  font-size: 13px;
  color: #222000;
  font-weight: 500;
}

.d-form .form-control,
.d-form .enquiry-form .ui.selection.dropdown,
.d-form .bootstrap-tagsinput {
  height: 45px;
  border-radius: 4px;
  border-color: #e9ecf3 !important;
  background: #fff;
}

.d-form select.form-control {
  height: 45px !important;
}

.d-form .form-group {
  margin-bottom: 20px;
}

.d-form .form-control:focus {
  border-color: #c5c5c5 !important;
}

.btn.active,
.btn:active {
  background-image: initial;
}

.d-btn {
  padding: 12px 42px;
  border-radius: 4px;
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  border-color: #4680ff;
  cursor: pointer;
  color: #fff;
}

.d-btn-default {
  background: #dedede;
  color: rgb(61, 61, 61);

}

.d-btn.d-btn-default:hover {
  background: #c9c9c9;
  color: rgb(61, 61, 61);
}

.d-btn:hover,
.d-btn:focus {
  background: #2a67ea;
  border-color: #3c77f9;
  outline: 0;
  color: #fff;
  background-position: right center !important;
  box-shadow: none;
}

.grey-text {
  color: #8b8e96;
}

.activity {
  padding-left: 30px;
  position: relative;
  margin: 20px 0 40px;
}

.activity p,
.activity div {
  margin-bottom: 30px;
}

.activity img {
  width: 100px;
  margin-right: 10px;
  border-radius: 4px;
}

.activity:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 1;
  width: 1px;
  height: 96%;
  background: #ded5d5;
}

.activity-dots {
  position: relative;
}

.activity-dots:before {
  content: "";
  position: absolute;
  top: 2px;
  width: 11px;
  height: 11px;
  z-index: 1;
  border-radius: 20px;
  background: #ded5d5;
  left: -30px;
}

.activity-dots.red:before {
  background: #f35454;
}

.activity-dots.blue:before {
  background: #4680ff;
}

.activity-dots.green:before {
  background: #4bc0c0;
}

.focus-links {
  padding-bottom: 0px;
  margin: 0 3px;
  font-weight: 500;
}

.focus-links:hover {
  border-bottom: 1px solid #222;
  border-color: #4680ff;
  color: #4680ff;
}

.toggle-group {
  margin-bottom: 15px;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 15px;
}

.toggle-group label {
  font-size: 15px !important;
}

.toggle-group .ui.toggle.checkbox {
  margin: 0;
}

.toggle-group .toggle-group .ui.toggle.checkbox .box:before,
.toggle-group .toggle-group .ui.toggle.checkbox label:before,
.toggle-group .toggle-group .ui.toggle.checkbox .box:before,
.toggle-group .toggle-group .ui.toggle.checkbox label:before {
  background: #acb5c7 !important;
}

.toggle-group .ui.toggle.checkbox input:checked~.box:before,
.toggle-group .ui.toggle.checkbox input:checked~label:before {
  background: #4680ff !important;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
}

.toggle-group .ui.toggle.checkbox input:focus:checked~.box:before,
.toggle-group .ui.toggle.checkbox input:focus:checked~label:before {
  background: #4680ff !important;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
}

.toggle-group .ui.toggle.checkbox .box:before,
.toggle-group .ui.toggle.checkbox label:before {
  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset -1px 1px 3px 0 rgba(0, 0, 0, 0.14) !important;
}

.toggle-group .ui.toggle.checkbox .box:hover::before,
.toggle-group .ui.toggle.checkbox label:hover::before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

.toggle-group .ui.toggle.checkbox input~.box:after,
.toggle-group .ui.toggle.checkbox input~label:after {
  left: 4px;
}

.toggle-group .ui.toggle.checkbox input:checked~.box:after,
.toggle-group .ui.toggle.checkbox input:checked~label:after {
  left: 2.15rem;
}

.toggle-group .ui.toggle.checkbox .box:after,
.toggle-group .ui.toggle.checkbox label:after {
  width: 1.1rem;
  height: 1.1rem;
  top: 3px;
  box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.34) !important;
  background: #fff;
}

.toggle-group:last-child {
  border: none;
}

.table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 30px !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
  top: 25px;
  left: 2px;
  height: 20px;
  width: 20px;
  font-size: 18px;
  line-height: 22px;
  border: none;
  background-color: #2e6cf2;
  box-shadow: none;
}

.dataTables_length .form-control,
.dataTables_filter .form-control {
  height: 36px !important;
}

.table .d-profile-name {
  color: #222;
}

.table .d-sidebar-left__profile-box {
  padding: 0;
}

.table .d-profile-image {
  margin: 0;
}

.table td {
  vertical-align: middle;
}

.table .d-btn {
  padding: 8px 30px;
  min-width: initial;
}

.table .ui.selection.dropdown {
  min-width: 8em;
  background: #e9ecf3;
  border: 1px solid rgba(34, 36, 38, 0);
}

.table td,
.table th {
  border-bottom: 1px solid rgba(233, 236, 243, 0.88);
  padding: 15px 15px 15px 15px;
  white-space: nowrap;
  border-top: none;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid rgba(233, 236, 243, 0.88);
  border-top: 1px solid rgba(233, 236, 243, 0.88);
  background: rgba(233, 236, 243, 0.3);
  color: #727c8c;
  font-weight: 500;
  border-right: 1px solid rgba(233, 236, 243, 0.88);
}

.table thead th:last-child {
  border-right: none;
}

.table .d-profile-name {
  padding-left: 52px;
  font-size: 14px;
}

.table .info-card__content-name {
  color: #333;
}

a:focus,
a:active,
.page-link:focus {
  outline: 0;
  box-shadow: none;
}

.d-box-table {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.d-box-table .l-chart-header {
  margin-bottom: 20px;
  border-bottom: none;
  padding: 0 30px;
}

.d-stats__chart-header .ui.selection.dropdown {
  min-width: 4em;
  border: 1px solid #f5f5f5;
}

.d-label {
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 0;
  font-size: 13px;
}

.d-label.d-label-primary {
  background: #4680ff;
}

.d-label.d-label-success {
  background: #29c37c;
}

.d-label.d-label-danger {
  background: #f35454;
}

.d-label.d-label-info {
  background: #0fb9d4;
}

.d-label.d-label-warning {
  background: #ffc107;
}

.name-td__name {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.name-td__post {
  font-size: 12px;
  color: #929292;
  letter-spacing: .3px;
}

.dots {
  width: 8px;
  height: 8px;
  z-index: 1;
  border-radius: 20px;
  background: #ded5d5;
  display: inline-block;
  position: relative;
  top: -4px;
  margin-left: 20px;
}

.dots.primary {
  background: #2e6cf2;
}

.dots.secondary {
  background: #ffffff;
}

.dots.info {
  background: #0fb9d4;
}

.dots.danger {
  background: #f35454;
}

.dots.warning {
  background: #deaa31;
}

.dots.success {
  background: #29c37c;
}

.first-fix-td {
  width: 100px;
}

/* pricing-table */
.pricing-card__header {
  padding: 20px 20px;
  border-bottom: 1px solid #f3f3f3;
}

.pricing-card__header h3 {
  font-weight: 300;
  font-size: 30px;
  color: #999;
  float: left;
}

.pricing-card__header span {
  float: right;
  font-size: 27px;
  font-weight: 500;
  letter-spacing: -1px;
  position: relative;
  top: 7px;
}

.pricing-card-container {
  margin-top: 60px;
}

.pricing-card__content {
  padding-bottom: 20px;
}

.pricing-card__list {
  padding: 20px;
  margin-bottom: 0;
}

.pricing-card__list-item {
  color: #333;
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #f3f3f3;
}

.pricing-card__list-item:last-child {
  border: none;
}

.pricing-card {
  min-height: 300px;
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(17, 59, 123, 0.06);
}

.pricing-card .btn {
  width: 86%;
}

.p-heading {
  font-weight: 500;
}

.p-text {
  font-size: 16px;
  margin: 16px 0px;
  color: #949494;
}

.q-box {
  margin-bottom: 50px;
  padding: 10px;
}

.q-box h3 {
  margin-bottom: 18px;
}

.q-box p {
  font-size: 15px;
  line-height: 27px;
  color: #333;
}

.l-d-tabs {
  margin-top: 40px;
}

.nav-tabs {
  border-color: #f3f3f3;
  border: none;
}

.d-tabs.nav-tabs .nav-link {
  border-radius: 0;
}

.d-tabs.nav-tabs .nav-item:first-child .nav-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.d-tabs.nav-tabs .nav-item:last-child .nav-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: #f3f3f3 #f3f3f3 #fff;
  border: none;
}

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.d-nav-link,
.d-nav-link:active,
.d-nav-link:hover,
.d-nav-link:focus {
  border: 1px solid #4680ff !important;
  background: transparent;
  color: #4680ff;
  border-right: none !important;
}

.d-tabs.nav-tabs .nav-item:last-child .d-nav-link {
  border-right: 1px solid #4680ff !important;
}

.d-nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff !important;
}

.d-nav-link--right {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.d-nav-link--left {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.d-nav-link.btn {
  padding: 6px 21px;
  border: none;
  cursor: pointer;
  min-width: 50px;
}

.s-stat-box {
  padding: 30px 10px;
}

.s-stat-box p {
  color: #61656d;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}

.s-stat-box span {
  font-size: 14px;
  color: #abaeb3;
}

.d-border-right {
  border-right: 1px solid #f3f3f3;
}

.d-border-left {
  border-left: 1px solid #f3f3f3;
}

.d-border-top {
  border-top: 1px solid #f3f3f3;
}

.d-border-bottom {
  border-bottom: 1px solid #f3f3f3;
}

/* task-header */
.task-card .l-chart-header {
  margin: 0;
}

.task-card .d-label {
  padding: 1px 10px;
  font-size: 13px;
}

.task-card .notification-box.account-box {
  width: 180px;
  top: 0px;
}

.task-card .notification-box:before {
  display: none;
}

.btn-header .d-header__left {
  padding-top: 11px !important;
}

.btn-header .d-header__left .btn {
  margin-left: 20px;
}

.chart-header__action-box {
  position: relative;
}

.ellipsis {
  position: relative;
  cursor: pointer;
  display: inline-block;
  cursor: pointer;
  font-size: 0;
  padding: 5px 0;
}

.ellipsis-dots {
  width: 4px;
  height: 4px;
  background: #aaa;
  border-radius: 10px;
  margin-right: 3px;
  display: inline-block;
}

.task-header .progress {
  margin-top: 11px;
}

.task-box {
  padding: 10px 30px;
}

.task-box .ui.checkbox {
  display: block;
}

.task-box__list-item {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}

.task-box__list-item:last-child {
  border: none;
}

.task-box__list-item .t-check label {
  font-size: 15px;
  padding-left: 40px;
  padding-right: 50px;
  color: #333;
  margin: 0;
}

.task-box__list-item .t-check .ui.checkbox {
  margin: 0;
}

.task-box__list-item:hover .reinit-icon {
  display: inline-block;
}

.reinit-icon {
  position: absolute;
  right: 0;
  top: 0;
  color: #447efe;
  z-index: 1;
  display: none;
  padding: 17px 0px;
}

.reinit-icon i.fa {
  padding: 6px;
  font-size: 16px;
  cursor: pointer;
}

.t-check.task-completed label {
  text-decoration: line-through;
}

.color-red {
  color: #ff2e2e;
}

/* inbox */
.d-main-container {
  height: 100%;
}

.inbox-page .big-container {
  height: 100%;
}

.inbox-page .inbox-container {
  height: calc(100% - 70px);
}

.inbox-left__search {
  position: relative;
}

.inbox-left__search i.fa {
  font-size: 17px;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 14px;
  cursor: pointer;
}

.inbox-left {
  width: 400px;
  height: 100%;
  background: #fff;
  float: left;
}

.inbox-left .inbox-left__search {
  background: #fff;
  padding: 15px;
}

.inbox-left .inbox-left__search i.fa {
  right: 16px;
  top: 15px;
}

.inbox-left__left-item {
  border-bottom: 1px solid rgba(34, 44, 60, 0.05);
  border-right: 1px solid #f3f3f3;
  cursor: pointer;
}

.inbox-left__left-item:hover {
  background: #f3f3f3;
}

.inbox-left__left-item .info-card {
  padding: 20px;
}

.inbox-left__left-item .info-card__content__name {
  font-size: 15px;
}

.inbox-left__left-item .d-sidebar-left__profile-box {
  border: none;
}

.inbox-left__left-item.opened {
  background: #fff;
  border-right: none;
}

.inbox-left__list-box {
  height: calc(100% - 75px);
  background: #f6f9ff;
}

.inbox-right {
  float: right;
  background: #fff;
  height: 100%;
  width: calc(100% - 400px);
  position: relative;
}

.inbox-right__header {
  height: 76px;
  background: #fff;
}

.l-inbox-right__header {
  padding: 18px 10px 0 10px;
}

.compose-modal .form-control {
  border: none;
  font-size: 15px;
}

.compose-modal .dismiss-modal {
  right: 0;
  top: 0;
}

.compose-modal .compose-header {
  padding: 14px;
  position: relative;
}

.compose-modal .compose-header .form-control {
  padding-left: 100px;
}

.compose-modal .compose-header span {
  position: absolute;
  top: 32px;
  left: 42px;
  font-size: 13px;
  color: #2f6df3;
  letter-spacing: .5px;
}

.compose-modal textarea {
  height: 270px;
  line-height: 24px;
  padding: 30px 40px 10px 40px;
}

.compose-modal .compose-footer {
  padding: 20px 30px;
}

.compose-modal input::-webkit-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.compose-modal input::-moz-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.compose-modal input::-ms-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.compose-modal textarea::-webkit-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.compose-modal textarea::-moz-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.compose-modal textarea::-ms-input-placeholder {
  color: #aaa;
  font-weight: 100;
  letter-spacing: .5px;
  font-size: 13px;
}

.inbox-right__content {
  padding: 50px 100px;
  height: calc(100% - 76px);
}

.inbox-right__content h1 {
  font-size: 30px;
}

.inbox-right__content span {
  color: #b9b9b9;
}

.inbox-right__content p {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 20px;
}

.compose-icon {
  padding-top: 10px;
}

.compose-icon i.fa {
  margin-right: 20px;
  font-size: 18px;
  color: #b9bdc5;
  cursor: pointer;
}

.inbox-right__content-footer {
  margin-top: 100px;
}

.inbox-right__content-footer .form-control {
  height: 170px !important;
}

.inbox-right__content-footer .emojionearea-editor {
  height: 100%;
}

.emojionearea.focused {
  box-shadow: none;
}

.my-scrollbar1,
.my-scrollbar2 {
  height: calc(100% - 76px);
}

.tooltip-inner {
  max-width: 200px;
  padding: 9px 15px;
  color: #dcdcdc;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.menu-icon {
  position: absolute;
  left: -12px;
  top: 21px;
  z-index: 11;
  font-size: 17px;
  color: #222;
  cursor: pointer;
}

.step-container {
  width: 70%;
  margin: 40px auto 50px;
  font-size: 0;
}

.steps-progress {
  position: relative;
  overflow: initial;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem;
  margin: auto;
  border: none;
  height: 6px;
  border-radius: 0;
  width: 33.33%;
  display: inline-block;
  background: #f7f7f7;
}

.steps-dots {
  position: absolute;
  top: -12px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #316ef3;
  border: 10px solid #fff;
  z-index: 1;
  text-align: center;
  box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.1);
}

.steps-dots.left {
  left: -15px;
}

.steps-dots.right {
  right: -15px;
}

.steps-dots i.fa {
  display: none;
}

.step-text {
  position: absolute;
  top: 35px;
  width: 86px;
  display: inline-block;
  left: -35px;
  font-size: 12px;
  font-weight: 400;
}

.steps-progress .complete.steps-dots {
  border-color: transparent;
}

.steps-progress .complete.steps-dots .step-text {
  color: #316ef3;
}

.steps-progress .complete i.fa {
  color: #fff;
  display: inline-block;
}

/* info card */
.info-card {
  font-size: 0;
}

.info-card__img {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  vertical-align: top;
}

.info-card__img img {
  width: 100%;
  border-radius: 100px;
}

.info-card__content {
  padding-left: 15px;
  display: inline-block;
  width: calc(100% - 60px);
  vertical-align: top;
  position: relative;
  font-size: 14px;
}

.info-card__content__name {
  color: #222;
  padding-top: 0px;
  font-size: 19px;
  font-weight: 500;
  margin-bottom: 3px;
}

.info-card__content__text {
  font-size: 13px;
  color: #585858;
}

.info-card__icons {
  font-size: 13px;
  margin-top: 10px;
}

.info-card__icons a {
  margin-right: 5px;
}

.ac-btn {
  padding-top: 20px;
  margin-top: 20px;
}

.ac-btn .btn {
  width: 49%;
}

.progress {
  height: 6px;
}

/* tags input */
.bootstrap-tagsinput .tag {
  margin-right: 2px;
  color: white;
  background: #4680ff;
  padding: 4px 0px 4px 9px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .4px;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  margin: 2px 0;
}

.bootstrap-tagsinput {
  padding: 3px 6px;
  height: auto !important;
  display: block;
  box-shadow: none;
}

.bootstrap-tagsinput input {
  display: inline-block;
  vertical-align: middle;
}

.bootstrap-tagsinput .tag [data-role="remove"] {
  padding: 8px;
  background: #416ed0;
}

/* custom tags */
.search-tags__list span {
  background: #474747;
  padding: 8px 9px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  margin: 3px 1px;
  cursor: pointer;
}

.search-tags-swiper .swiper-slide {
  width: inherit;
  width: auto;
  float: left;
}

.search-tags-swiper {
  border: 1px solid #ececec;
  padding: 9px;
}

/* d-chat-box */
.d-chatbox {
  position: fixed;
  bottom: -345px;
  right: 30px;
  width: 300px;
  z-index: 11;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  -ms-transition: transform .3s ease;
  -o-transition: transform .3s ease;
  transition: transform .3s ease;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.d-chatbox .dots {
  top: 0px;
  margin-left: 0;
  margin-right: 7px;
}

.d-chatbox .form-control {
  border: none;
  border-top: 1px solid #f3f3f3;
  border-radius: 0;
  padding-right: 70px;
}

.d-chatbox [data-scrollbar] {
  display: block !important;
}

.d-chatbox .emojionearea .emojionearea-button {
  right: 40px;
  top: 11px;
}

.d-chatbox.open {
  -webkit-transform: translateY(-345px);
  -moz-transform: translateY(-345px);
  -ms-transform: translateY(-345px);
  -o-transform: translateY(-345px);
  transform: translateY(-345px);
}

.d-chatbox__header {
  background: #222c3c;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 13px 20px;
  color: #fff;
  cursor: pointer;
}

.d-chatbox-body {
  background: #fff;
  height: 300px;
  overflow: hidden;
}

.d-chatbox-body p {
  margin: 0;
}

.chat-notification {
  border-radius: 100px;
  padding: 5px;
  position: absolute;
  left: -8px;
  font-size: 13px;
  top: -6px;
}

.chat-arrow {
  padding: 2px;
}

.chat-arrow.rotate {
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  -ms-transform: rotateX(-180deg);
  -o-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}

.d-chatbox__footer {
  position: relative;
}

.chat-icons {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.chat-icons i.fa {
  font-size: 17px;
  color: #bbb;
}

i.fa.d-send-icon {
  font-size: 18px;
  padding: 14px;
  color: #4680ff;
  border-radius: 40px;
  cursor: pointer;
}

.my-side {
  text-align: right;
}

.my-side .chat-time-tag {
  margin-right: 15px;
}

.other-side {
  text-align: left;
}

.other-side .chat-time-tag {
  margin-left: 15px;
}

.my-side__text {
  background: #f7f7f7;
  padding: 12px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  margin: 10px 15px;
  display: inline-block;
  max-width: 220px;
}

.other-side__text {
  background: #222c3c;
  padding: 12px;
  border-radius: 20px;
  border-bottom-left-radius: 0;
  margin: 10px 15px;
  display: inline-block;
  color: #fff;
  max-width: 220px;
}

.chat-time-tag {
  display: block;
  font-size: 11px;
  margin-top: -6px;
  letter-spacing: .5px;
  margin-bottom: 6px;
  color: #999;
}

.day-divider {
  display: block;
  text-align: center;
  padding: 5px 0;
  color: #4680ff;
  font-size: 13px;
  border-bottom: 1px solid #eaeaea;
}

/* Range slider */
.noUi-target {
  background: #e9ecf3;
  border-radius: 4px;
  border: none;
  box-shadow: none;
}

.noUi-horizontal {
  height: 3px;
}

.noUi-connect {
  background: #4680ff;
}

.noUi-handle {
  border: none;
  border-radius: 41px;
  background: #4680ff;
  cursor: default;
  box-shadow: none;
  cursor: pointer;
}

.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  left: -17px;
  top: -9px;
}

.noUi-handle:after,
.noUi-handle:before {
  display: none;
}

.noUi-horizontal .noUi-handle:focus {
  outline: 0;
}

/* d-card */
.d-card {
  background: #fff;
}

.d-card .search-tags-swiper {
  border: none;
  margin: 5px 0;
}

.d-card .search-tags__list span {
  background: #ffffff;
  padding: 8px 14px;
  border: 1px solid #bbb;
  font-size: 12px;
  color: #bbb;
  display: inline-block;
  border-radius: 50px;
  margin: 3px 1px;
  cursor: pointer;
}

.d-card .info-card__img {
  position: absolute;
  right: 0;
  margin: auto;
  left: 0;
  top: -40px;
  width: 80px;
  height: 80px;
}

.d-card .ellipsis-dots {
  width: 3px;
  height: 3px;
}

.d-card .swiper-wrapper {
  justify-content: center;
}

.d-card__footer {
  padding-bottom: 30px;
}

.d-card__header {
  /* background: url(../images/new/1.jpg) no-repeat; */
  background-size: cover;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.d-card__header img {
  width: 100%;
}

.d-card__body {
  padding: 50px 30px 20px;
  position: relative;
}

.d-card__body .chart-header__action-box {
  position: absolute;
  top: 12px;
  right: 8px;
}

.d-card__body .ellipsis {
  transform: rotate(90deg);
}

.d-card__body .notification-box:before {
  display: none;
}

.d-card__body .notification-box.account-box {
  top: -5px;
}

.d-card__body i.fa {
  padding: 0;
  margin-right: 12px;
  width: 17px;
}

.ellipsis.ellipsis-vertical {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.d-card__name {
  font-size: 25px;
  margin: 12px 0 6px;
  font-weight: 400;
}

.d-card__hint {
  color: #999;
}

.d-card__regular-text {
  font-size: 14px;
  line-height: 20px;
  color: #222;
  margin-top: 20px;
}

/* inline chart list */
.chart-stats__list.inline-chart-list {
  font-size: 0;
}

.chart-stats__list.inline-chart-list .chart-stats__list-item {
  display: inline-block;
  padding: 0 10px;
}

.chart-stats__list.inline-chart-list.two .chart-stats__list-item {
  width: 50%;
}

.chart-stats__list.inline-chart-list.three .chart-stats__list-item {
  width: 33.33%;
}

.chart-stats__list.inline-chart-list.four .chart-stats__list-item {
  width: 25%;
}

.chart-stats__list.inline-chart-list.five .chart-stats__list-item {
  width: 20%;
}

.chart-stats__toggle .chart-stats__list-item {
  margin-bottom: 0;
}

.chart-stats__toggle .toggle-group {
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.chart-stats__toggle .toggle-group label {
  font-size: 14px !important;
}

.chart-stats__toggle .toggle-group .ui.toggle {
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}

.chart-stats__list-item:last-child .toggle-group {
  border: none;
}

/* d-tabs-2 */
.d-tabs-2 {
  justify-content: center;
  border-bottom: 1px solid #d6d6d6;
}

.d-tabs-2 a {
  font-weight: 400;
  margin: 0 16px;
  border-bottom: 3px solid transparent;
  padding: 10px 6px;
  color: #909090;
  position: relative;
  display: inline-block;
  top: 2px;
  font-size: 15px;
}

.d-tabs-2 a.active {
  border-color: #417cfc;
  border-width: 3px;
  color: #3e79fb;
}

.d-tabs-2 .activity-dots {
  position: absolute;
  right: 0;
  top: 0;
}

.d-tabs-2 .activity-dots:before {
  width: 7px;
  height: 7px;
  background: #f35454;
  right: 0;
  left: auto;
}

.tab-content {
  padding-top: 20px;
}

/* value text */
.value-text {
  color: #39bb62;
  font-size: 15px;
  margin-left: 5px;
}

.value-text i.fa {
  margin-left: 5px;
}

/* circle icon */
.circle-icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background: #e9ecf3;
  padding: 10px;
  text-align: center;
}

.circle-icon.fa {
  color: #fff !important;
}

.small-text {
  font-size: 12px !important;
  position: absolute;
  right: 10px;
  top: 0;
  color: #999 !important;
  bottom: 0;
  margin: auto;
  height: 16px;
}

.notify-box li {
  position: relative;
}

.notify-box a {
  padding: 10px 50px 10px 10px;
}

/* d-stats-box */
/* d-comment-box*/
.d-comment-box .info-card__img {
  width: 50px;
  height: 50px;
}

.d-comment-box .info-card__content__name {
  font-size: 16px;
}

.d-comment-box .info-card__content__text {
  display: block;
}

.d-comment-box .d-label {
  display: inline-block;
  font-size: 11px !important;
  margin-top: 5px;
  color: #fff;
}

.d-comment-box .l-chart-header {
  margin-bottom: 0;
}

.d-comment-box .info-card__content {
  padding-right: 80px;
}

.d-comment-box .small-text {
  right: 0px;
}

.d-comment-box__list-item {
  padding: 20px 20px;
  border-bottom: 1px solid #f3f3f3;
  position: relative;
}

.d-comment-box__list-item .ui.selection.dropdown {
  border-radius: 4px;
  padding: 1px 34px 1px 7px;
  margin: 6px 6px 0 6px;
  min-width: 100px;
}

.d-comment-box__list-item .ui.dropdown>.text {
  font-size: 12px;
}

.d-comment-box__list-item .ui.dropdown>.dropdown.icon {
  font-size: 12px;
}

.d-comment-box__list-item .ui.dropdown .menu>.item {
  font-size: 12px;
}

.d-comment-box__list-item .s-f-s {
  margin-bottom: 5px;
  display: inline-block;
}

.ticket-tags.search-tags__list {
  margin-top: 10px;
}

.ticket-tags.search-tags__list span {
  background: #e9ecf3;
  padding: 4px 9px;
  font-size: 12px;
  color: #656565;
}

.ticket-tags__tags {
  display: inline-block;
}

.s-f-s {
  font-size: 12px;
}

.d-comment-box__list-item:hover {
  background: #fafafa;
}

.d-comment-box__list-item:last-child {
  border: none;
}

.d-stats__chart-body.tab-content {
  padding: 0;
}

.d-comment-box__action-btn {
  position: absolute;
  font-size: 11px;
  padding: 10px 30px;
  bottom: 0;
  display: none;
  right: 0;
}

.d-comment-box__action-btn a {
  margin: 0 3px;
  font-weight: 500;
  text-transform: uppercase;
  color: #427dfd;
}

.d-comment-box__action-btn a:hover {
  text-decoration: underline;
}

.d-comment-box__list-item:hover .d-comment-box__action-btn {
  display: inline-block;
}

/* profile -icon group-box */
.profile-icon-group-box .info-card__img {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  vertical-align: top;
  border: 2px solid #fff;
  margin-right: -25px;
  background: #fff;
  position: relative;
}

.profile-icon-group-box .info-card__img.count-member {
  background: rgba(42, 103, 234, 0);
  color: #888;
  margin-left: 21px;
  border: none;
  overflow: initial;
}

.profile-icon-group-box a {
  display: block;
}

.profile-group__more-member {
  background: #252525;
  position: absolute;
  top: 71%;
  left: 13%;
  padding: 10px;
  width: 130px;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  z-index: 11;
}

.profile-group__more-member ul {
  margin: 0;
}

.profile-group__more-member li {
  padding: 4px 0;
  font-size: 12px;
  font-weight: 400;
  color: #d8d8d8;
}

.total-member-count {
  display: block;
  text-align: center;
  font-size: 16px;
  padding-top: 10px;
}

/* login page */
.d-link {
  color: #417cfc;
  font-weight: 500;
  border-bottom: 1px solid #427cfd;
}

.d-link:focus,
.d-link:hover {
  color: #417cfc;
}

.login-container {
  width: 450px;
  margin: auto;
  padding-top: 10%;
}

.login-container hr {
  margin: 30px 0;
}

.login-container .d-link {
  margin-left: 4px;
}

.login-container .d-box {
  padding: 30px 30px 15px;
}

.login-container .ui.checkbox {
  margin: 10px 0 30px;
}

.login-container .ui.checkbox p {
  margin: 0;
}

.hint-text {
  font-size: 12px;
  padding: 6px 0;
  display: inline-block;
}

.full-width {
  width: 100% !important;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  width: 20px;
  height: 20px;
}

.ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus~label:before,
.ui.checkbox input:checked:focus~.box:before,
.ui.checkbox input:checked:focus~label:before,
.ui.checkbox input:checked~.box:before,
.ui.checkbox input:checked~label:before {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  border: none;
}

.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after,
.ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after,
.ui.checkbox .box:after,
.ui.checkbox label:after {
  color: #fff !important;
}

.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after,
.ui.radio.checkbox input:checked~.box:after,
.ui.radio.checkbox input:checked~label:after,
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  background: #fff !important;
}

.ui.checkbox .box:after,
.ui.checkbox label:after {
  font-size: 12px;
  width: 20px;
  height: 20px;
}

.ui.checkbox .box,
.ui.checkbox label {
  line-height: 20px;
}

/* project-page */
.d-box.d-box--project {
  padding: 30px;
}

.d-box.d-box--project h3 {
  margin-top: 0;
}

.project-box,
.info-card__content {
  position: relative;
}

.project-box .profile-icon-group-box p,
.info-card__content .profile-icon-group-box p {
  font-weight: 500;
  margin-bottom: 8px;
}

.project-box .info-card__img,
.info-card__content .info-card__img {
  margin-right: -1px;
}

.project-box .info-card__img.count-member,
.info-card__content .info-card__img.count-member {
  margin-left: 0;
}

.project-box .chart-header__action-box,
.info-card__content .chart-header__action-box {
  position: absolute;
  top: -8px;
  right: -15px;
  z-index: 1;
  padding: 10px;
}

.project-box .notification-box.account-box,
.info-card__content .notification-box.account-box {
  top: 0px;
}

.project-box .notification-box:before,
.info-card__content .notification-box:before {
  display: none;
}

.project-box .notification-box i.fa,
.info-card__content .notification-box i.fa {
  margin-right: 5px;
}

.project-box .progress,
.info-card__content .progress {
  width: 200px;
}

.project-box .small-profile,
.info-card__content .small-profile {
  display: none;
}

.project-box .info-card__img:hover .small-profile,
.info-card__content .info-card__img:hover .small-profile {
  display: inline-block;
}

.project-box__progress-text {
  margin-top: 10px;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
}

.project-box__details {
  margin-top: 50px;
}

.project-box__name {
  margin-bottom: 10px;
  position: relative;
}

.profile-group__more-member {
  display: none;
}

.info-card__img.count-member:hover .profile-group__more-member {
  display: inline-block;
}

textarea.form-control {
  height: 150px !important;
}

.no-overlap .info-card__img {
  margin-right: 5px;
  font-size: 0;
  border: none;
}

.remove-member i.fa {
  font-size: 11px;
  position: absolute;
  top: -3px;
  right: -2px;
  color: #ffffff;
  background: #ff6f08;
  border-radius: 50px;
  padding-top: 3px;
  width: 16px;
  box-shadow: 0px 1px 5px 0 rgba(0, 0, 0, 0.27);
  height: 16px;
  cursor: pointer;
  text-align: center;
  display: none;
}

.info-card__img:hover i.fa {
  display: inline-block;
}

.profile-icon-group-box.overlapped>.info-card__img {
  margin-right: -25px;
  -webkit-transition: margin .3s ease;
  -moz-transition: margin .3s ease;
  -ms-transition: margin .3s ease;
  -o-transition: margin .3s ease;
  transition: margin .3s ease;
}

.profile-icon-group-box.overlapped .count-member {
  margin-left: 23px;
  marign-right: 0;
}

.profile-icon-group-box.overlapped.animated:hover .info-card__img {
  margin-right: -3px;
}

.profile-icon-group-box.overlapped.animated:hover .count-member {
  margin-left: 3px;
}

.add-project-box .profile-icon-group-box {
  margin: 10px 0 25px !important;
}

/* Map */
.common-map {
  width: 100%;
  height: 869px;
}

.d-map-container {
  position: relative;
}

.card-block1__card1 {
  max-width: 640px;
  background: #fff;
}

.card1--new1 .card1__image {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  max-height: initial;
  height: 100%;
  width: 200px;
}

.card1--new1 .card1__image img {
  width: 100%;
}

.card1--new1 .card1__content {
  padding-top: 10px;
  padding: 20px;
  padding-left: 225px;
}

.map-search input:focus {
  border: 1px solid #eee;
  box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.1);
  outline: 0;
}

.map-filter-zoom {
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 11;
}

.map-filter-zoom p {
  font-weight: 100;
  font-size: 30px;
  cursor: pointer;
  background: rgba(60, 118, 249, 0.96);
  color: #ffffff;
  width: 39px;
  border-radius: 7px;
  text-align: center;
  margin-bottom: 5px;
  box-shadow: 1px 2px 4px 0 rgba(68, 125, 253, 0.34);
}

.pin-details {
  position: absolute;
  bottom: 50px;
  left: 50px;
  box-shadow: 0px 5px 14px 0 rgba(0, 0, 0, 0.08);
  z-index: 11;
}

.filter-box {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px 30px;
  z-index: 11;
}

.filter-box span {
  width: 40px;
  text-align: center;
  border-radius: 50px;
  height: 40px;
  margin: 10px;
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  color: #fff;
  display: inline-block;
  padding-top: 12px;
  box-shadow: 0px 5px 14px 0 rgba(0, 39, 130, 0.27);
  position: relative;
  z-index: 11;
  cursor: pointer;
}

.filter-box span i {
  display: none;
}

.filter-box span i.active {
  display: inline-block;
}

.filter-box .filter-container {
  background: #fff;
  box-shadow: 0px 5px 14px 0 rgba(0, 0, 0, 0.08);
  width: 400px;
  height: 400px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: 20px;
  display: none;
}

.filter-box .filter-container.expand {
  display: inline-block;
}

.filter-box p {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 6px;
  color: #272727;
}

.close:focus,
.close:hover {
  color: #fff;
}

.map-notification {
  position: absolute;
  top: 30px;
  z-index: 1;
  width: 250px;
  text-align: center;
  display: block;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 5px 14px 0 rgba(0, 0, 0, 0.08);
}

.map-notification .btn {
  padding: 8px 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
}

.map-notification i {
  font-size: 12px;
  margin-left: 5px;
}

.map-notification .alert {
  margin-bottom: 0;
  border: none !important;
}

#description {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  margin: 10px 10px 0 0;
  border-radius: 2px 0 0 2px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  font-family: Roboto;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 300px;
  border-radius: 4px;
  height: 40px;
  box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.1);
  margin: 30px;
  z-index: 11 !important;
  position: absolute !important;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}

.pac-container {
  box-shadow: 0px 5px 14px 0 rgba(0, 0, 0, 0.12);
}

.pac-item {
  border: none;
  padding: 5px 10px;
}

.pac-matched {
  color: #2e6cf2;
}

.form-control:focus {
  box-shadow: none;
}

.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff;
  box-shadow: 1px 1px 12px 0 #306cf3;
}

.datepicker td,
.datepicker th {
  border-radius: 50px;
  border: none;
  padding: 10px;
  color: #515a69;
  width: 40px;
  height: 40px;
}

.datepicker th {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  border-radius: 0;
  color: #fff;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  color: #cacaca;
}

.dropdown-menu.datepicker-dropdown {
  box-shadow: 2px 4px 14px 0 #c7cdda;
  border: none;
}

.datepicker-days thead tr,
.datepicker-months thead tr,
.datepicker-years thead tr,
.datepicker-decades thead tr,
.datepicker-centuries thead tr,
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
  background: #2e6cf2;
  background-image: -moz-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: -webkit-linear-gradient(left, #2e6cf2 0%, #4680ff 100%);
  background-image: linear-gradient(to right, #2e6cf2 0%, #4680ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2e6cf2', endColorstr='#4680ff', GradientType=1);
  color: #fff;
}

.datepicker {
  padding: 0;
}

th.dow {
  background: #fff;
  color: #515a69;
}

.d-sidebar-left {
  left: -250px;
}

.d-sidebar-left.open {
  -webkit-transform: translateX(250px);
  -moz-transform: translateX(250px);
  -ms-transform: translateX(250px);
  -o-transform: translateX(250px);
  transform: translateX(250px);
}

.pad-20 {
  padding: 20px;
}

.w200 {
  width: 200px;
}

.small-profile {
  background: #fff;
  padding: 25px 22px 15px;
  box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
}

.small-profile.small-profile--popup {
  position: absolute;
  left: -50%;
  top: 130%;
  z-index: 11;
}

.small-profile:before {
  content: "";
  position: absolute;
  right: 0px;
  left: 27px;
  top: -9px;
  width: 0;
  height: 0;
  z-index: 1;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 9px solid #d9dde6;
}

.small-profile .info-card__img {
  width: 80px;
  height: 80px;
}

.small-profile .small-profile__name {
  margin: 8px 0;
  margin-bottom: 20px !important;
}

.small-profile .small-profile__name p {
  font-size: 15px;
  margin-bottom: 2px;
}

.small-profile .small-profile__name span {
  font-size: 12px;
}

.small-profile .btn {
  padding: 6px;
}

.small-profile .d-link {
  border: none;
  margin-top: 10px;
  font-size: 13px;
  display: inline-block;
}

@media (max-width: 992px) {
  .d-main-container.slide-left {
    padding-left: 0;
    width: 100%;
  }

  .d-header__left h3 {
    font-size: 18px;
  }

  .mobile-hide {
    display: none !important;
  }

  .d-sidebar-left {
    -webkit-transform: translateX(250px);
    -moz-transform: translateX(250px);
    -ms-transform: translateX(250px);
    -o-transform: translateX(250px);
    transform: translateX(250px);
  }

  .d-sidebar-left.open {
    left: -250px !important;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  .sidebar-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1111;
    visibility: hidden;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transition: opacity .3s ease, visibility .3s ease;
    -moz-transition: opacity .3s ease, visibility .3s ease;
    -ms-transition: opacity .3s ease, visibility .3s ease;
    -o-transition: opacity .3s ease, visibility .3s ease;
    transition: opacity .3s ease, visibility .3s ease;
  }

  .sidebar-wrapper.open {
    visibility: visible;
    opacity: 1;
  }

  .stats-container-2 {
    padding: 20px 5px;
  }

  .d-stats__chart-body {
    padding: 5px;
  }

  .left-profile-tabs {
    position: fixed;
    background: #fff;
    top: 0;
    z-index: 1111;
    left: -300px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: transform .3s ease;
    -moz-transition: transform .3s ease;
    -ms-transition: transform .3s ease;
    -o-transition: transform .3s ease;
    transition: transform .3s ease;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }

  .left-profile-tabs.open {
    -webkit-transform: translateX(300px);
    -moz-transform: translateX(300px);
    -ms-transform: translateX(300px);
    -o-transform: translateX(300px);
    transform: translateX(300px);
  }

  .profile-main-container {
    margin: 0;
    width: 100%;
  }

  .activity img {
    margin-bottom: 15px;
  }

  .d-header {
    padding-right: 0;
  }

  .d-header__right-icon {
    margin: 0 1px;
  }

  /* Notification box */
  .notification-box {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    z-index: 111;
  }

  .notification-box.account-box {
    position: absolute;
    top: 51px;
    right: 0;
    left: auto;
    z-index: 11;
  }

  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
  table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
    line-height: 19px;
  }

  .close-notification {
    margin-left: 5px;
  }

  .d-box {
    padding: 20px 20px;
  }
}

/* 
color
padding
margin
border
border-radius
width
height
box-shadow
clamp lines 
*/
/* color */
.f-color-white {
  color: #fff;
}

.f-color-black {
  color: #222;
}

.f-color-grey {
  color: #999;
}

.no-bg-color {
  background: transparent;
}

.bg-color-white {
  background-color: #fff;
}

.bg-color-black {
  background-color: #222;
}

.bg-color-grey {
  background-color: #fafafa;
}

/* padding css */
/* all no padding */
.no-padding {
  padding: 0 !important;
}

.no-padding-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-tb {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

/* most common padding */
.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 10px;
}

.padding-30 {
  padding: 10px;
}

.padding-top-50 {
  padding-top: 50px !important;
}

.padding-top-100 {
  padding-top: 100px !important;
}

.padding-bottom-50 {
  padding-bottom: 50px !important;
}

.padding-bottom-100 {
  padding-bottom: 100px !important;
}

/* padding end */
/* margin css */
/* all no margin */
.no-margin {
  margin: 0 !important;
}

.no-margin-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.no-margin-tb {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

/* most common margin */
.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 10px;
}

.margin-30 {
  margin: 10px;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-100 {
  margin-top: 100px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.margin-bottom-100 {
  margin-bottom: 100px !important;
}

/* margin end */
/* border css */
.no-border {
  border: none !important;
}

.border {
  border: 1px solid #f3f3f3;
}

.border-left {
  border-left: 1px solid #f3f3f3;
}

.border-right {
  border-right: 1px solid #f3f3f3;
}

.border-top {
  border-top: 1px solid #f3f3f3;
}

.border-bottom {
  border-bottom: 1px solid #f3f3f3;
}

/* border-radius css */
.no-bor-rad {
  border-radius: 0 !important;
}

.bor-rad-4 {
  border-radius: 4px !important;
}

.bor-rad-100 {
  border-radius: 100% !important;
}

.bor-rad-top-lr {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.bor-rad-bottom-lr {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.bor-rad-left-tb {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.bor-rad-right-tb {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

/* width */
.w20 {
  width: 20% !important;
}

.w80 {
  width: 80% !important;
}

.w25 {
  width: 25% !important;
}

.w75 {
  width: 75% !important;
}

.w60 {
  width: 60% !important;
}

.w30 {
  width: 30% !important;
}

.w50 {
  width: 50% !important;
}

.full-width,
.w100 {
  width: 100% !important;
}

.full-height,
.f100 {
  height: 100%;
}

/* box-shadow */
.b-shadow {
  box-shadow: 0px 1px 3px 1px rgba(17, 59, 123, 0.06);
}

/* line clamps */
.d-line-clamp-1 {
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.d-line-clamp-2 {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.d-line-clamp-3 {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.d-line-clamp-4 {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.d-line-clamp-5 {
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}


.d-card__header {
  background: green;
  height: 76px;
}

/*# sourceMappingURL=hotelui-common.css.map */